/** @jsx jsx */
import { Box, Container, Title, View } from '@bottlebooks/gatsby-design-system';
import { jsx } from 'theme-ui';

export default function Section({ variant = 'light', children, ...rest }) {
  return (
    <View
      sx={{
        paddingY: 4,
        backgroundColor: variant === 'light' ? 'transparent' : 'light',
      }}
      {...rest}
    >
      <Container fluid sx={{ maxWidth: 'container.fluid' }}>
        {children}
      </Container>
    </View>
  );
}

Section.Wrapper = function ({ children }) {
  return children;
};

Section.Title = function ({ children, ...rest }) {
  return (
    <Box sx={{ marginBottom: 3 }} {...rest}>
      <Title sx={{ color: 'primary' }}>{children}</Title>
    </Box>
  );
};

Section.Body = function ({ children, ...rest }) {
  return <Box {...rest}>{children}</Box>;
};

/**
 * Each of these corresponds to a section object in Sanity
 */
export { default as ContactSection } from './ContactSection';
export { default as Faqs } from './Faqs';
export { default as IframeSection } from './IframeSection';
export { default as ReferencedSection } from './ReferencedSection';
export { default as SimpleGridSection } from './SimpleGridSection';
export { default as SimpleHeader } from './Slider/SimpleHeader';
export { default as Slider } from './Slider/Slider';
export { default as TextSection } from './TextSection';

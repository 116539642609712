/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import { Box, Label, Text } from '@bottlebooks/gatsby-theme-base';
import { Plural, Trans } from '@lingui/macro';
import { jsx } from 'theme-ui';
import useLink from '@bottlebooks/gatsby-theme-event/src/useLink';
import ExhibitorListRowSmall from '@bottlebooks/gatsby-theme-event/src/components/SiteSearch/ExhibitorListRowSmall';
import ListRowSmall from '@bottlebooks/gatsby-theme-event/src/components/SiteSearch/ListRowSmall';
import ProductListRowSmall from '@bottlebooks/gatsby-theme-event/src/components/SiteSearch/ProductListRowSmall';

export default function SearchResults({
  exhibitors,
  products,
  searchTerm,
  ...rest
}) {
  const link = useLink();
  const productCount = products?.length || 0;
  const exhibitorCount = exhibitors?.length || 0;
  return (
    <Box
      sx={{
        position: 'absolute',
        backgroundColor: ['light', null, 'white'],
        borderRadius: 'default',
        boxShadow: [null, null, 'overlay'],
        left: 0,
        right: 0,
        paddingY: 4,
      }}
      {...rest}
    >
      {exhibitors?.slice(0, 6).map((exhibitor) => (
        <ExhibitorListRowSmall
          key={exhibitor.exhibitorId}
          to={link.exhibitor(exhibitor)}
          exhibitor={exhibitor}
          searchTerm={searchTerm}
          sx={{
            ':hover': {
              backgroundColor: ['background', 'light'],
            },
          }}
        />
      ))}
      {exhibitorCount < 6 &&
        products?.slice(0, 6 - exhibitorCount).map((product) => (
          <ProductListRowSmall
            key={product.productId}
            to={`/packages/${product.slug.current}`}
            product={product}
            searchTerm={searchTerm}
            sx={{
              ':hover': {
                backgroundColor: ['background', 'light'],
              },
            }}
          />
        ))}
      {(exhibitorCount > 6 || productCount > 6) && (
        <Box sx={{ paddingTop: 4 }}>
          <Label sx={{ color: 'primary', marginBottom: 3, paddingX: 3 }}>
            <Trans>Explore</Trans>
          </Label>
          {exhibitorCount > 6 && (
            <ListRowSmall
              to={`${link.exhibitors()}#searchTerm=${encodeURIComponent(
                searchTerm
              )}`}
              sx={{ paddingY: 1 }}
            >
              <Text sx={{ fontWeight: 'body' }}>
                <Plural
                  value={exhibitorCount}
                  one={<Trans>1 exhibitor with "{searchTerm}"</Trans>}
                  other={<Trans># exhibitors with "{searchTerm}"</Trans>}
                />
              </Text>
            </ListRowSmall>
          )}
          {productCount > 6 && (
            <ListRowSmall
              variant="text"
              to={`/packages#searchTerm=${encodeURIComponent(searchTerm)}`}
              sx={{ paddingY: 1 }}
            >
              <Text sx={{ fontWeight: 'body' }}>Alle Weinpakete anzeigen</Text>
            </ListRowSmall>
          )}
        </Box>
      )}
      {exhibitorCount === 0 && productCount === 0 && (
        <Text sx={{ paddingX: 4 }}>
          <Trans>Nothing found. Try another search term.</Trans>
        </Text>
      )}
    </Box>
  );
}

/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import { Container, Text, Title } from '@bottlebooks/gatsby-theme-base';
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout/Layout';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { jsx } from 'theme-ui';

const queryClient = new QueryClient();

export default function CaliforniaLandingPage() {
  const data = fetch;
  return (
    <QueryClientProvider client={queryClient}>
      <Layout>
        <Container>
          <Title>Webinars</Title>

          <Webinars />
        </Container>
      </Layout>
    </QueryClientProvider>
  );
}

function Webinars() {
  const { isLoading, error, data, isFetching } = useQuery('webinars', () =>
    fetch('/.netlify/functions/webinars').then((res) => res.json())
  );

  if (isLoading) return <Text>Loading...</Text>;
  if (error) return <Text>An error has occurred: {error.message}</Text>;
  console.log(data.length);
  return (
    <Text>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Datum</th>
            <th>webinar_id</th>
            <th>schedule</th>
            <th>registration_url</th>
          </tr>
        </thead>
        <tbody>
          {data.map((webinar) =>
            (webinar.schedules?.length
              ? webinar.schedules
              : webinar.fallback.schedules.map((date) => ({ date }))
            ).map((schedule) => (
              <tr key={`${webinar.webinar_id}-${schedule.schedule}`}>
                <td>{webinar.name}</td>
                <td>{schedule.date}</td>
                <td>{webinar.webinar_id}</td>
                <td>{schedule.schedule}</td>
                <td>{webinar.registration_url}</td>

                {/* <td>{JSON.stringify(webinar, null, 2)}</td> */}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </Text>
  );
}

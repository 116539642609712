import React from 'react';
import promotionalImageUrl from '../../assets/regions/customImages/DWI20_Reg_1700x1200_Saale-Unstrut-.jpeg';
import backgroundImageUrl from '../../assets/regions/customImages/Saale-Unstrut1-scaled.jpeg';
import mapImage from '../../assets/regions/maps/Anbaugebiet-Saale-Unstrut-800x775.jpeg';
import mainImage from '../../assets/regions/mainImages/Saale-Unstrut2-scaled.jpeg';
import FormattedText from '../../cms-design-system/Elements/MDXFormattedText';
import Description from './saaleUnstrut.mdx';

export default {
  // Bottlebooks code
  id: 'de_saale',
  // Nice-to-read slug
  slug: 'saale-unstrut',
  countryCode: 'de',
  name: 'Saale-Unstrut',
  country: 'Germany',
  mainImageUrl: mainImage,
  description: <FormattedText FormattedText={Description} />,
  mapImageUrl: mapImage,
  association: {
    name: 'Weinbauverband Saale-Unstrut',
    street: 'Querfurter Straße 10',
    postalCode: '06632',
    city: 'Freyburg',
    country: 'Germany',
    telephone: '+49 344 64 26 11 0',
    fax: '+49 344 64 2 94 16',
    website: 'https://www.natuerlich-saale-unstrut.de',
    // Simulating what we would need from GraphQL
    websiteText: 'www.natuerlich-saale-unstrut.de',
    email: 'mailto:weinbauverband.saale-unstrut@t-online.de',
    emailText: 'weinbauverband.saale-unstrut@t-online.de',
  },
  grapeVarieties: [
    'Müller-Thurgau',
    'Weißburgunder',
    'Riesling',
    'Dornfelder',
    'Bacchus',
    'Silvaner',
  ],
  characteristics: [
    {
      title: 'Geographische Lage',
      description:
        '51. Breitengrad nördlicher Breite – in den Tälern von Saale und Unstrut',
    },
    {
      title: 'Klima',
      description:
        'Jahresmitteltemperatur bei 9,1° C, geringe Niederschlagsmenge (500 mm/Jahr), druchschnittlich 1.600 Sonnenstunden/Jahr',
    },
    {
      title: 'Böden',
      description: 'Muschelkalk, Buntsandstein, Lösslehm, Kupferschiefer',
    },
    {
      title: 'Rebfläche 2022',
      description: '840 ha, 3 Bereiche, 4 Großlagen, 34 Einzellagen',
    },
    {
      title: 'Rebsorten 2019 [weiß 74,7%, rot 25,3%]',
      description:
        'Müller-Thurgau (15,2%), Weißburgunder (13,7%), Riesling (8,9%), Dornfelder (6,8%), Bacchus (6,4%), Silvaner (6,1%)',
    },
  ],
  custom: {
    title: 'Saale-Unstrut - Kostbare Weine – einzigartige Kulturlandschaft',
    title2: 'Romantik in unberührter Natur',
    shortDescription:
      'Natur trifft Idylle: Im nördlichsten Weinanbaugebiet gibt es feingliedrige Weine und romantische Schlösser zu entdecken.',
    promotionalImageUrl,
    backgroundImageUrl,
    externalLink:
      'https://www.deutscheweine.de/tourismus/in-den-anbaugebieten/saale-unstrut/',
  },
};

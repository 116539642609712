import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Willkommen im zweitgrößten Weinbaugebiet Deutschlands: der Pfalz! Unangefochtener Rebsorten-Spitzenreiter auf den über 23.760 Hektar Rebfläche ist hier ganz klar der Riesling, gefolgt von den Burgundersorten.`}</p>
    <p>{`Wer die Pfalz entdecken möchte, folgt am besten der Deutschen Weinstraße: Auf 85 Kilometern verbindet sie 130 Weinorte zwischen Bockenheim bis nach Schweigen an die Grenze zum Elsass.`}</p>
    <p>{`Begrenzt durch den Pfälzerwald wachsen hier viele klassische Rebsorten – allen voran der Riesling. Doch auch die Weiß- und Grauburgunder sind stark im Kommen. Daneben gehören unter anderem Silvaner, Müller-Thurgau, Scheurebe, Gewürztraminer und Kerner zum vielfältigen Weißwein-Angebot der Pfalz.`}</p>
    <p>{`In dem mediterranen Klima, in dem auch Mandeln, Feigen, Zitronen und Oliven gedeihen, nehmen ebenfalls die Rotweine zunehmend einen wichtigen Platz ein. Von spritzig-frischen Weißherbsten aus Portugieser über fruchtige Spätburgunder bis hin zum tiefdunklen Dornfelder – inzwischen tragen 40 Prozent der Rebstöcke zwischen Rhein und Haardtgebirge rote Trauben. Die Pfalz besitzt damit das größte deutsche Rotweingebiet.`}</p>
    <p>{`Und ob es nun die Sonne oder einfach die Freude an der Geselligkeit ist: In der Pfalz wird besonders gerne und viel gefeiert. Kein Wunder, dass das größte Weinfest Deutschlands in Bad Dürkheim stattfindet. Doch auch zum Auftakt der Weinfest-Saison, wenn die Mandelbäume die Weinstraße in ein herrliches Rosa tauchen bis zum Martinus-Weinfest im November – in der Pfalz ist eigentlich immer irgendwo etwas los. Ebenfalls lockt die Region mit hervorragendem Essen. Entlang der Deutschen Weinstraße haben sich inzwischen Spitzenköche etabliert, die mit der lokalen Küche erfolgreich experimentieren und einen Besuch lohnen. Aber auch kleine Gasthäuser, die mit hausgemachten Spezialitäten aufwarten, zeigen, dass die Nähe zu Frankreich nicht nur geographisch ist: In der Pfalz wissen die Menschen, wie Savoir-Vivre gelebt wird!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`“Meet the Winzer” – virtuelle Treffen mit den Winzerinnen und Winzern:`}</h3>
    <p>{`Wie sieht der Tag eines Winzers oder einer Winzerin aus? Woher weiß man, dass es Zeit für die Lese ist und wann der Wein fertig ist? All das und noch viel mehr können Sie die teilnehmenden Weingüter ganz persönlich fragen. In individuellen virtuellen Meetingräumen stehen Ihnen die Weingüter nach den Online-Verkostungen Rede und Antwort. Den Link erhalten alle virtuellen Besucher während der Live-Verkostung.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
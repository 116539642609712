// The variants are actually imported by the theme, but colocating helps finding them more easily.

const titleVariants = {
  _base: {
    fontFamily: 'heading',
    fontWeight: 'heading',
    margin: 0,
    padding: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  largest: {
    // Largest should use Garamond with 400
    fontFamily: 'body',
    fontWeight: 'body',

    fontSize: ['largeTitle', 'largestTitle'],
    lineHeight: ['largeTitle', 'largestTitle'],
    letterSpacing: ['largeTitle', 'largestTitle'],
  },

  banner: {
    variant: 'title._base',
    fontSize: 52,
    lineHeight: '74px',
    letterSpacing: '-0.76px',
  },

  large: {
    variant: 'title._base',
    fontSize: ['title', 'largeTitle'],
    lineHeight: ['title', 'largeTitle'],
    letterSpacing: ['title', 'largeTitle'],
  },

  default: {
    variant: 'title._base',
    fontSize: 'title',
    lineHeight: 'title',
    letterSpacing: 'title',
  },

  small: {
    variant: 'title._base',
    fontSize: 'smallTitle',
    lineHeight: 'smallTitle',
    letterSpacing: 'smallTitle',
  },

  smallest: {
    variant: 'title._base',
    fontSize: 'smallestTitle',
    lineHeight: 'smallestTitle',
    letterSpacing: 'smallestTitle',
  },

  section: {
    variant: 'title._base',
    fontWeight: 'bold',
    fontSize: 'large',
  },
};

export default titleVariants;

/**
 * @typedef {keyof typeof titleVariants} TitleVariant
 */

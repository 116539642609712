import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Mit rund 470 Hektar ist die Region Mittelrhein eines der kleinsten deutschen Weinbaugebiete. Die steilen Rebhänge entlang des Rheinufers gehören dafür zu den besonders malerischen Kulissen.`}</p>
    <p>{`Die Weinberge des Weinanbaugebiets Mittelrhein erstrecken sich über mehr als 100 Kilometer von Bingen bis vor die Tore Bonns. Steile Rebhänge und zahllose, enge, schroffe Felsrücken bieten ein imposantes Landschaftsbild auf beiden Seiten des Rheins. Vor allem Riesling findet hier ideale Wachstumsbedingungen. Mit 70 Prozent der Anbaufläche ist er die Nummer Eins unter den Weißweinreben am Mittelrhein. Daneben gedeihen noch Müller-Thurgau, Kerner und Burgunder. Die wichtigste rote Rebsorte ist der Spätburgunder.`}</p>
    <p>{`Karge Tonschieferböden, windgeschützte Lagen und der Rhein als Wärmespeicher schaffen hervorragende Bedingungen für die Reben. Für die Winzerinnen und Winzer hingegen bedeuten die enormen Steillagen eine große Herausforderung und viel körperliche Arbeit. Doch der aufwendige Weinbau ist gleichzeitig die Grundlage für die Spitzenqualität der Weine am Mittelrhein.`}</p>
    <p>{`So anstrengend die Arbeit in den Weinbergen am Mittelrhein ist, so malerisch ist auch die Kulisse. Von Burgen und Schlossruinen gesäumte Rebhänge und pittoreske, mittelalterliche Städtchen entlang des Rheinufers – wer im Oberen Mittelrheintal zu Besuch ist, versteht sofort, warum das Gebiet 2002 zum Weltkulturerbe geadelt wurde. Doch auch darüber hinaus, lässt sich im gesamten Mittelrheingebiet pure Rheinromantik erleben, zahlreich sind die Ausflugsziele. Im Herzen dieser einzigartigen Flusslandschaft liegt Koblenz, eine der größten Weinbau treibenden Städte überhaupt, das malerische Bacharach ist ein altes Weinhandelszentrum.`}</p>
    <p>{`Grundsätzlich findet sich in den Dörfern und Städten kaum eine Gasse ohne Straußwirtschaft, Weinstube oder Gutsschänke. Und auch die Feste und Erlebnistage um den Wein, wie der Weinmarkt in Oberwesel oder das Bacharacher Weinfest, haben das ganze Jahr über Saison.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
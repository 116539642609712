import React from 'react';
import promotionalImageUrl from '../../assets/regions/customImages/DWI20_Reg_1700x1200_HessischeBergstrasse.jpeg';
import backgroundImageUrl from '../../assets/regions/customImages/HB2-scaled.jpeg';
import mapImage from '../../assets/regions/maps/Anbaugebiet-Hessische-Bergstrasse-600x648.jpeg';
import mainImage from '../../assets/regions/mainImages/HB1-scaled.jpeg';
import FormattedText from '../../cms-design-system/Elements/MDXFormattedText';
import Description from './hessischeBergstrasse.mdx';

export default {
  // Bottlebooks code
  id: 'de_hesse',
  // Nice-to-read slug
  slug: 'hessische-bergstrasse',
  countryCode: 'de',
  name: 'Hessische Bergstrasse',
  country: 'Germany',
  mainImageUrl: mainImage,
  description: <FormattedText FormattedText={Description} />,
  mapImageUrl: mapImage,
  association: {
    name: 'Weinbauverband Hessische Bergstraße e.V.',
    street: 'Kettelerstraße 29',
    postalCode: '64646',
    city: 'Heppenheim',
    country: 'Germany',
    telephone: '+49 6252 7 56 54',
    fax: '+49 6252 78 82 56',
    website: 'https://www.bergstraesser-wein.de',
    // Simulating what we would need from GraphQL
    websiteText: 'www.bergstraesser-wein.de',
    email: 'mailto:info@bergstraesser-wein.de',
    emailText: 'info@bergstraesser-wein.de',
  },
  grapeVarieties: [
    'Riesling',
    'Grauburgunder',
    'Spätburgunder',
    'Weißburgunder',
    'Silvaner',
  ],
  characteristics: [
    {
      title: 'Geographische Lage',
      description:
        'eingebettet zwischen Neckar, Rhein und Main im Schutz des Odenwalds; dazu die Odenwälder Weininsel um Groß-Umstadt',
    },
    {
      title: 'Klima',
      description:
        'beste Sonneneinstrahlung und genügend Niederschläge, geschützt gegen kalten Ostwind durch den Odenwald',
    },
    {
      title: 'Böden',
      description: 'Leichtere Böden mit unterschiedlich hohem Lössanteil',
    },
    {
      title: 'Rebfläche 2022',
      description: '460 ha, 2 Bereiche, 3 Großlagen, 23 Einzellagen',
    },
    {
      title: 'Rebsorten 2019 [weiß 79% · rot 21%]',
      description:
        'Riesling (39,7%), Grauburgunder (12,1%), Spätburgunder (11%), Weißburgunder (5,4%) and Silvaner',
    },
  ],
  custom: {
    title: 'Hessische Bergstraße - Frühlingsgarten und Weininsel',
    title2: 'Frühlings-erwachen',
    shortDescription:
      'Während es anderswo noch kalt ist, macht ein früher Frühling die Hessische Bergstraße schon zu Beginn des Jahres entdeckenswert.',
    promotionalImageUrl,
    backgroundImageUrl,
    externalLink:
      'https://www.deutscheweine.de/tourismus/in-den-anbaugebieten/hessische-bergstrasse/',
  },
};

/** @jsxRuntime classic */
/** @jsx jsx */
import { Container, Link, P, Title } from '@bottlebooks/gatsby-theme-base';
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout';
import { Trans } from '@lingui/macro';
import { jsx } from 'theme-ui';

export default function FourOhFourPage({ pageContext }) {
  return (
    <Layout locale={pageContext.locale}>
      <Container>
        <Title sx={{ marginY: 3 }}>Seite nicht gefunden</Title>
        <P>Wir konnten nicht finden, was Sie gesucht haben.</P>
        <P>
          Starten Sie auf der <Link to="/">Homepage</Link>.
        </P>
      </Container>
    </Layout>
  );
}

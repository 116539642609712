/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout/Layout';
import { jsx } from 'theme-ui';
import { Section } from '../cms-design-system';

export default function MapPage() {
  return (
    <Layout page={{ title: 'Karte' }}>
      <Section.Body sx={{ height: 1000 }}>
        <iframe
          style={{
            width: '100%',
            height: '100%',
            border: 0,
          }}
          src="https://www.deepmoire-maps.com/VirtuelleWeinTour_v24/"
          width="400"
        />
      </Section.Body>
    </Layout>
  );
}

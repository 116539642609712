import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Willkommen in Rheinhessen, Deutschlands größtem Weinanbaugebiet! Auf fast 27.000 Hektar erstrecken sich hier sanfte Hügel und Reben, so weit das Auge reicht.`}</p>
    <p>{`Im Norden und Osten vom großen Rheinbogen erschlossen, erstreckt sich Rheinhessen zwischen den Weinbaustädten Alzey, Worms, Mainz und Bingen. Bekannt ist die Region zwar für seinen Riesling vom Rhein und aus dem Hügelland, doch auch Müller-Thurgau und die Traditionsrebsorte Silvaner, hier bekannt unter dem Kürzel RS, wird hier in vielen Weinbergen angebaut. Daneben hat sich die Rotweinfläche innerhalb eines Jahrzehnts mehr als verdoppelt. Dieser Rotweinboom wurde insbesondere von der Rebsorte Dornfelder getragen. Ein Drittel der rheinhessischen Rebfläche ist inzwischen mit roten Rebsorten bestockt.`}</p>
    <p>{`Rheinhessen war als Anbaugebiet schon bei den Römern einst sehr beliebt. Kein Wunder also, dass fast jedes rheinhessische Dorf auf eine lange Weinbautradition zurückblicken kann. Und was die Römer damals wahrscheinlich auch schon bemerkten: In Rheinhessen herrscht ideales Weinbauklima. Geschützt von Gebirgszügen im Westen, ist das Wetter niederschlagsarm, sommerwarm und wintermild. Da fühlen sich nicht nur Reben wohl!`}</p>
    <p>{`Die idealen Arbeitsbedingungen weiß auch die neue, junge rheinhessische Generation von Winzerinnen und Winzern zu schätzen, die durch hervorragende Weine auf sich aufmerksam macht. Darüber hinaus blubbert es ganz ordentlich in Rheinhessen: Schon vor 25 Jahren wurde hier der erste Winzersekt auf den Markt gebracht. Daraus ist in der Region in den vergangenen Jahren eine beachtliche Sektkultur entstanden.`}</p>
    <p>{`Den ein oder anderen Korken kann man in Rheinhessen wunderbar nach einer Weinbergswanderung oder einer Radtour durch die tausend Hügel knallen lassen. Ob in einer der vielen Straußwirtschaften und Gutsschänken oder in der gehobenen Gastronomie: Das größte Anbaugebiet Deutschlands hat nicht nur viele Rebhügel zu bieten, auch die nächste kulinarische Entdeckung für Gourmets ist nie weit.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
// @ts-check
import { Text } from '@bottlebooks/gatsby-theme-base';
import * as Base from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductHeader';
import { useLingui } from '@lingui/react';
import { graphql } from 'gatsby';
import React from 'react';
import { jsx } from 'theme-ui';

export * from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductHeader';

export default function ProductHeader({
  product,
  gap = 3,
  titleVariant = 'small',
  ...rest
}) {
  return (
    <React.Fragment>
      <Base.ProductProducer producer={product?.producer} />
      <Base.ProductName
        product={product}
        variant={titleVariant}
        sx={{ paddingBottom: gap }}
      />
      <ProductHeaderDetails product={product} />
    </React.Fragment>
  );
}

export function ProductHeaderDetails({ product }) {
  return (
    <>
      <Base.ProductRegion product={product} />
      <Base.ProductVarieties product={product} />
      <ProductAnalytics
        product={product}
        sx={{ paddingTop: 1, color: 'text' }}
      />
      <Base.ProductPrice pricing={product.pricing} />
    </>
  );
}

function ProductAnalytics({ product, ...rest }) {
  return (
    <Text variant="smallest" {...rest}>
      {[
        ProductAlcohol({ product }),
        ProductResidualSugar({ product }),
        ProductTotalAcidity({ product }),
        ProductBottleSize({ product }),
      ]
        .filter(Boolean)
        .join(', ')}
    </Text>
  );
}

export const ProductProducer = Base.ProductProducer;

export const ProductName = Base.ProductName;

export const ProductRegion = Base.ProductRegion;

export function ProductAlcohol({ product }) {
  const { i18n } = useLingui();
  if (product.alcohol == null) return null;
  return `${i18n.number(product.alcohol, analyticFormat)}% Vol`;
}

export function ProductResidualSugar({ product }) {
  const { i18n } = useLingui();
  if (product.residualSugar == null) return null;
  return `Restzucker ${i18n.number(product.residualSugar, analyticFormat)}g/l`;
}

export function ProductTotalAcidity({ product }) {
  const { i18n } = useLingui();
  if (product.totalAcidity == null) return null;
  return `Säure ${i18n.number(product.totalAcidity, analyticFormat)}g/l`;
}

export function ProductBottleSize({ product }) {
  const { i18n } = useLingui();
  if (product.bottleSize == null) return null;
  return product.bottleSize != null && `${i18n.number(product.bottleSize)}l`;
}

const analyticFormat = {
  minimumFractionDigits: 1,
  maximumFractionDigits: 2,
};

export const fragment = graphql`
  fragment sanity_ProductHeader on SanityProduct {
    alcohol
    totalAcidity
    residualSugar
    bottleSize
    pricing {
      priceRange
      priceStrategy
    }
  }
`;

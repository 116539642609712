/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import { jsx } from 'theme-ui';

export default function RestaurantIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 64 64"
      xmlSpace="preserve"
      sx={{ width: 50, height: 50, color: '#80cc28' }}
      {...rest}
    >
      <g transform="matrix(1.33333 0 0 -1.33333 0 64)">
        <g fillOpacity="1" stroke="none" transform="scale(.1)">
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M240.012 480c132.457 0 240-107.539 240-240S372.469 0 240.012 0c-132.461 0-240 107.539-240 240s107.539 240 240 240"
          ></path>
          <path
            fill="#fff"
            fillRule="nonzero"
            d="M94.059 367c-.13-28.09 68.832-141.891 98.621-163.289 9.179 3.019 12.34 15 22.84 16.328 4.628-1.828 7.039-5.687 9.871-8.961 24.269-28.027 48.461-56.129 72.687-84.187 2.883-3.34 5.531-7.012 8.902-9.782 6.668-5.48 13.942-5.66 20.258.442 5.903 5.699 6.121 12.48 1.16 19.09-1.839 2.449-4.148 4.57-6.328 6.75-72.129 72.089-144.261 144.168-216.461 216.187-2.98 2.973-5.269 7.231-11.55 7.422m120.5-163.398c-25.008-19.153-25.547-19.332-45.071 4.597-32.789 40.211-60.289 83.781-82.039 130.922-3.89 8.438-7.578 17.02-7.18 26.578.56 13.539 11.481 19.59 23.032 12.563 4.078-2.481 7.578-6.043 11-9.453 72.23-72.008 144.41-144.079 216.609-216.118 5.328-5.32 10.77-10.57 12.602-18.269 2.937-12.371-2.301-24.621-13.043-30.641-12-6.722-24.489-4.84-34.571 6.559-22.82 25.801-45.16 52.012-67.726 78.019-4.313 4.969-8.762 9.821-13.613 15.243"
          ></path>
          <path
            fill="#fff"
            fillRule="nonzero"
            d="M177.949 187.012c4.512-6.641-.23-10.082-4.019-13.684-14.059-13.367-28.282-26.558-42.231-40.019-9.347-9.02-10.211-17.149-3.058-23.789 6.449-6 14.621-4.872 22.961 3.832 18.296 19.078 36.339 38.41 54.589 57.539 3.899 4.089 7.149 9.031 12.59 10.98 4.821.098 6.309-2.742 6.91-5.91-.882-4.383-3.519-6.813-5.929-9.359-19.684-20.813-39.262-41.731-59.141-62.352-13.633-14.14-30.281-15.16-41.82-3.109-10.949 11.429-9.61 28.16 3.609 40.929 13.949 13.469 28.102 26.731 42.25 39.989 3.418 3.203 6.531 7.613 13.289 4.953"
          ></path>
          <path
            fill="#fff"
            fillRule="nonzero"
            d="M336.27 378.43c-11.95-12.02-23.372-23.52-34.801-35-4.039-4.071-7.809-8.469-12.207-12.102-19.891-16.367-29.043-35.387-15.274-59.316-10.179-19.172-22.636-27.043-31.547-20.032 1.93 9.282 11.981 12.34 16.258 20.36-.25 7.129-3.711 14.012-3.711 21.64-.008 13 3.703 24.43 12.762 33.68 17.27 17.641 34.828 34.988 52.301 52.418 4.648 4.652 9.429 8.5 16.219-1.648"
          ></path>
          <path
            fill="#fff"
            fillRule="nonzero"
            d="M290.629 239.949c-7.469-3.461-10.68-12.73-18.848-15.347-8.402 8.269-5 14.476 15.68 29.66 1.551 0 3.559.578 4.988-.09 22.262-10.262 39.602-3.723 55.223 14.027 13.098 14.879 27.918 28.223 41.93 42.301 4.75 4.781 9.378 8.328 16.109-.988-23.871-23.34-44.25-49.981-72.262-68.621-9.14-6.082-16.859-4.879-42.82-.942"
          ></path>
          <path
            fill="#fff"
            fillRule="nonzero"
            d="M380.391 335.371c.597-5.742-2.782-8.269-5.532-11.031-12.129-12.219-24.289-24.41-36.539-36.512-4.211-4.148-8.672-8.539-13.609-1.238-.91 3.871.859 6.398 3.078 8.64 13.07 13.149 26.18 26.258 39.301 39.372 4.551 4.539 9.109 7.046 13.301.769"
          ></path>
          <path
            fill="#fff"
            fillRule="nonzero"
            d="M300.23 312.641c16.11 18.058 33.391 34.55 50.032 50.609 8.269-3.41 9.129-7.801 4.879-12.172a1862.633 1862.633 0 00-43.129-42.918c-3.922-3.769-8.082-2.691-11.782 4.481"
          ></path>
        </g>
      </g>
    </svg>
  );
}

import React from 'react';
import promotionalImageUrl from '../../assets/regions/customImages/DWI20_Reg_1700x1200_Pfalz.jpeg';
import backgroundImageUrl from '../../assets/regions/customImages/Pfalz2-scaled.jpeg';
import mapImage from '../../assets/regions/maps/Anbaugebiet-Pfalz-800x1167.jpeg';
import mainImage from '../../assets/regions/mainImages/Pfalz1-scaled.jpeg';
import FormattedText from '../../cms-design-system/Elements/MDXFormattedText';
import Description from './pfalz.mdx';

export default {
  // Bottlebooks code
  id: 'de_pfalz',
  // Nice-to-read slug
  slug: 'pfalz',
  countryCode: 'de',
  name: 'Pfalz',
  country: 'Germany',
  mainImageUrl: mainImage,
  description: <FormattedText FormattedText={Description} />,
  mapImageUrl: mapImage,
  association: {
    name: 'Pfalzwein e.V.',
    street: 'Martin-Luther-Straße 69',
    postalCode: '67433',
    city: 'Neustadt/Weinstraße',
    country: 'Germany',
    telephone: '+49 6321 91 23 28',
    fax: '+49 6321 12 88 1',
    website: 'https://www.pfalz.de',
    // Simulating what we would need from GraphQL
    websiteText: 'www.pfalz.de',
    email: 'mailto:info@pfalz.de',
    emailText: 'info@pfalz.de',
  },
  grapeVarieties: [
    'Riesling',
    'Dornfelder',
    'Grauburgunder',
    'Müller-Thurgau',
    'Spätburgunder',
    'Portugieser',
    'Weißburgunder',
    'Kerner',
    'Chardonnay',
  ],
  characteristics: [
    {
      title: 'Geographische Lage',
      description:
        'Südlich von Worms bis an die französische Grenze und von den Hängen des Pfälzer Waldes bis in die Rheinebene',
    },
    {
      title: 'Klima',
      description: 'Hoher Anteil an Sonnentagen; Temperaturmittel bei 11 Grad',
    },
    {
      title: 'Böden',
      description:
        'Buntsandstein, kalkhaltige Lehm- und Tonböden, Mergel- und Keuperböden, eingestreuter Muschelkalk; Granit-, Porphyr- und Schiefertoninseln',
    },
    {
      title: 'Rebfläche 2022',
      description: '23.760 ha, 2 Bereiche, 25 Großlagen, 323 Einzellagen',
    },
    {
      title: 'Rebsorten 2019 [weiß 65.7%, rot 34.3%]',
      description:
        'Riesling (24.9%), Dornfelder (12.1%), Grauburgunder (7.7%),  Müller-Thurgau (7.6%), Spätburgunder (7.1%), Portugieser, Weißburgunder, Kerner und Chardonnay',
    },
  ],
  custom: {
    title: 'Pfalz - Genuss entlang der Deutschen Weinstraße',
    title2: 'Savoir-vivre entlang des Pfälzerwalds',
    shortDescription:
      'Das liebste Kind der Pfälzer Winzerinnen und Winzer ist der Riesling – oft kräftig und saftig ausgebaut.',
    promotionalImageUrl,
    backgroundImageUrl,
    externalLink:
      'https://www.deutscheweine.de/tourismus/in-den-anbaugebieten/pfalz/',
  },
};

// @ts-check
const getLinkBase = require('@bottlebooks/gatsby-theme-event/src/getLink');

/**
 *
 * @param {{ exhibitors: string; products: string; packages: string; }} context
 */
module.exports = function getLink(context) {
  return {
    ...getLinkBase(context),

    exhibitor: (exhibitor) => {
      if (!exhibitor) return null;
      return path(context.exhibitors, exhibitor?.slug?.current);
    },
    /**
     * Generates a link to a product.
     * If a producerId is provided, it links to the producer's product route, otherwise it uses the exhibitor route (this behavior will probably change in the future).
     * @param {ReturnType<import('../../components/useWines')>[number]} product
     * @returns
     */
    product: (product) => {
      if (!context) return undefined;
      if (!product) return undefined;
      return path(context.products, product.slug?.current);
    },

    packageItem: (packageItem) => {
      if (!packageItem) return undefined;
      return path(context.packages, packageItem.slug?.current);
    },
  };
};
function path(...segments) {
  return `/${segments.filter(Boolean).join('/')}`.replace(/\/\/+/g, '/');
}

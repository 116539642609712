/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import { jsx } from 'theme-ui';
import { Box, Flex, Link } from '@bottlebooks/gatsby-theme-base';
import SanityImage from 'gatsby-plugin-sanity-image';
import cssOverlay from '@bottlebooks/gatsby-theme-event/src/helpers/cssOverlay';

/**
 *
 * @param {Parameters<SanityImage>[0] & { withOverlay?: boolean }} props
 * @returns
 */
export default function CoverImage({
  asset,
  alt,
  withOverlay = false,
  ...rest
}) {
  if (!asset) return null;
  return (
    <Box sx={{ '::after': withOverlay ? cssOverlay() : undefined }}>
      <SanityImage
        asset={asset}
        alt={alt}
        sx={{
          display: 'block',
          position: 'relative',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
        {...rest}
      />
    </Box>
  );
}

/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout/Layout';
import { graphql, useStaticQuery } from 'gatsby';
import { jsx } from 'theme-ui';
import * as cms from '../cms';
import { Text } from '@bottlebooks/gatsby-design-system';
import { Section } from '../cms-design-system';

export default function CaliforniaLandingPage() {
  const {
    event: { eventId },
  } = useStaticQuery(graphql`
    {
      event {
        eventId
      }
    }
  `);
  const data = cms[`event_${eventId}`].index;
  return (
    <Layout>
      <Section.Body sx={{ height: 1000 }}>
        <Text>In progress</Text>
      </Section.Body>
    </Layout>
  );
}

import {
  Link,
  P,
  SemiTitle,
  Text,
  Title,
} from '@bottlebooks/gatsby-design-system';
import BlockContent from '@sanity/block-content-to-react';
import React from 'react';

const serializers = {
  // container: (props) => {
  //   console.log(props);
  // },
  marks: {
    link: (props) => {
      const { mark, children } = props;
      const { href } = mark;
      if (!href) return <span>{children}</span>;
      return href.includes('http') ? (
        <Link href={href}>{children}</Link>
      ) : (
        <Link to={href}>{children}</Link>
      );
    },
  },
  types: {
    block: (props) => {
      switch (props.node.style) {
        case 'h1':
          return <Title variant="large">{props.children}</Title>;
        case 'h2':
          return <Title>{props.children}</Title>;
        case 'h3':
          return <Title variant="small">{props.children}</Title>;
        case 'h4':
          return <SemiTitle>{props.children}</SemiTitle>;
        case 'h5':
          return <SemiTitle variant="small">{props.children}</SemiTitle>;
        case 'h6':
          return <SemiTitle variant="smallest">{props.children}</SemiTitle>;
        case 'p':
          return <P>{props.children}</P>;
        default:
          return <P>{props.children}</P>;
      }
    },
  },
};

export default function FormattedText({ children, ...rest }) {
  return <BlockContent blocks={children} serializers={serializers} />;
}

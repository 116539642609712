/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import { Box, Text, Title } from '@bottlebooks/gatsby-design-system';
import { Button } from '@bottlebooks/gatsby-theme-base';
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout/Layout';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import { RichText, Section, Slider } from '../../cms-design-system';

export default function SpeakerPage({ data }) {
  const { speaker } = afterQuery(data);

  return (
    <Layout>
      <Slider
        imageUrl={speaker.profileImage?.asset.url}
        title={speaker.name}
        height={[400, 400, 550, 800]}
      />
      <Section>
        <Box
          sx={{
            backgroundColor: 'primary',
            paddingX: [3, 4, 5, 6],
            paddingY: [4, 4, 5, 6],
            color: 'white',
            textAlign: 'center',
          }}
        >
          <Box sx={{ marginBottom: 3 }}>
            <Title sx={{ color: 'white' }}>{speaker.title}</Title>
          </Box>
          <Title sx={{ color: 'white' }}>{speaker.name}</Title>

          <Section.Body sx={{ marginY: 4 }}>
            <Text sx={{ fontWeight: 600 }}>
              <RichText>{speaker.description}</RichText>
            </Text>
          </Section.Body>
          <Button
            href={speaker.externalLinkUrl}
            variant="outline"
            sx={{ color: 'white', fontWeight: 600 }}
            target="_blank"
          >
            Weitere Informationen
          </Button>
        </Box>
      </Section>
    </Layout>
  );
}

function afterQuery(data) {
  const speaker = data?.sanitySpeaker;
  return {
    speaker,
  };
}

export const query = graphql`
  query ($id: String) {
    sanitySpeaker(id: { eq: $id }) {
      slug {
        current
      }
      name
      profileImage {
        asset {
          url
        }
      }
      description: _rawDescription
      externalUrl
      title
      introduction
    }

    allSanityPackageItem(filter: { speaker: { id: { eq: $id } } }) {
      nodes {
        name
      }
    }
  }
`;

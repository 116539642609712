/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import { gql, useQuery } from '@apollo/client';
import { jsx } from 'theme-ui';
import { SanityPage } from '../../cms-design-system';
import { Box, Flex, Link, Text } from '@bottlebooks/gatsby-design-system';

export default function PreviewSanityPage(props) {
  const { pageId } = props.params;
  const { data, loading, error /* , refetch */ } = useQuery(clientQuery, {
    variables: { id: pageId },
    context: { clientName: 'sanity' },
  });
  if (loading)
    return (
      <Flex align="center" justify="center" sx={{ height: '100vh' }}>
        <Text>Loading Preview</Text>
      </Flex>
    );

  if (!data.Page)
    return (
      <Flex align="center" justify="center" sx={{ height: '100vh' }}>
        <Text>Data not found.</Text>
        <Text>
          Possible causes: Confirm that you are logged into Sanity, or this page
          doesn't exist.
        </Text>
      </Flex>
    );

  const { slug } = afterQuery(data);
  return (
    <Box>
      <Flex
        align="center"
        justify="center"
        sx={{ paddingY: 3, backgroundColor: '#f3f3f3' }}
      >
        <Text>
          This is a preview.{'  '}
          <Link to={`/${slug.current}`}>View live version.</Link>
        </Text>
      </Flex>
      <SanityPage {...afterQuery(data)} />
    </Box>
  );
}

function afterQuery({ Page }) {
  // We have to prepend Sanity to all the typenames
  const mappedContent = Page.content.map((contentItem) => {
    const blocks = contentItem.blocks?.map((block) => {
      return { ...block, __typename: `Sanity${block.__typename}` };
    });
    return {
      ...contentItem,
      __typename: `Sanity${contentItem.__typename}`,
      blocks,
    };
  });
  return {
    ...Page,
    content: mappedContent,
  };
}

const clientQuery = gql`
  query ($id: ID!) {
    Page(id: $id) {
      ...SanityPage
    }
  }
  ${SanityPage.fragment}
`;

import { Box, Link, Grid } from '@bottlebooks/gatsby-design-system';
import React from 'react';
import discountImage from '../../../assets/WeinTour_10_Prozent-100x100.png';
import { MDXFormattedText, Section } from '../../../cms-design-system';
import Section10pcRabatt from '../mdxContent/section2/10pcRabatt.mdx';
import SectionMeetTheWinzer from '../mdxContent/section2/meetTheWinzer.mdx';

export default function WiederDabei() {
  return (
    <Section variant="dark">
      <Section.Title>Wieder mit dabei</Section.Title>
      <Section.Body>
        {/* Can this be replaced with a component that automatically calculates the # of columns? */}
        <Grid gap={4} columns={[1, 1, 2, 2]}>
          <Box sx={{ position: 'relative' }}>
            <Link to={'/aussteller'}>
              <img
                src={discountImage}
                style={{ float: 'right', right: 0, top: 0, width: 72 }}
              />
            </Link>
            <MDXFormattedText FormattedText={Section10pcRabatt} />
          </Box>
        </Grid>
      </Section.Body>
    </Section>
  );
}

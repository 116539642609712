/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import { jsx } from 'theme-ui';
import BaseProductListRow from '@bottlebooks/gatsby-theme-event/src/components/ProductsList/ProductListRow';

export * from '@bottlebooks/gatsby-theme-event/src/components/ProductsList/ProductListRow';

export default function ProductListRow(props) {
  return (
    <BaseProductListRow
      {...props}
      sx={{ img: { objectFit: 'contain !important' } }} // Workaround to fix the image display
    />
  );
}
ProductListRow.fragment = BaseProductListRow.fragment;

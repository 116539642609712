/** @jsx jsx */
import { gql } from '@apollo/client';
import Section from '@bottlebooks/gatsby-theme-event/src/components/Section/Section';
import { Trans } from '@lingui/macro';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import { RichText } from '../../../../cms-design-system';

export * from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandDescription';

export default function BrandDescription({ brand, ...rest }) {
  if (!brand || !brand.shortDescription) return null;
  return (
    <Section
      title={
        <Trans comment="The header of the Brand description - about the brand">
          About
        </Trans>
      }
      {...rest}
    >
      <RichText>{brand.shortDescription}</RichText>
    </Section>
  );
}

export const fragment = graphql`
  fragment sanity_BrandDescription on SanityExhibitor {
    shortDescription: _rawDescription
  }
`;

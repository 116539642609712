/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout/Layout';
import { graphql, useStaticQuery } from 'gatsby';
import { jsx } from 'theme-ui';
import * as cms from '../cms';
import { Section } from '../cms-design-system';

export default function CaliforniaLandingPage() {
  const {
    event: { eventId },
  } = useStaticQuery(graphql`
    {
      event {
        eventId
      }
    }
  `);
  const data = cms[`event_${eventId}`].index;
  return (
    <iFrame
      style={{
        width: '100%',
        height: '100vh',
        border: 0,
      }}
      src="https://kunden.dutyfarm.com/weintour/weintyp2022/"
    />
  );
}

/** @jsx jsx */
import {
  Box,
  Flex,
  Grid,
  SemiTitle,
  Text,
} from '@bottlebooks/gatsby-design-system';
import { graphql } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';
import { jsx } from 'theme-ui';
import { CallToAction, RichText, Section } from '..';

export default function SimpleGridSystem({
  title,
  blocks,
  style,
  callToAction,
}) {
  return (
    <Section variant={style.variant} sx={style}>
      <Section.Title>{title}</Section.Title>
      <Section.Body>
        <Grid
          columns={[1, null, blocks.length]}
          gap={4}
          sx={{ gridTemplateColumns: 'repeat(1fr)' }}
        >
          {blocks.map((block) => {
            switch (block.__typename) {
              case 'SanityIconBlock':
                return (
                  <Flex key={block._key} direction="column" align="center">
                    <GridImage
                      image={block.icon}
                      sx={{ marginBottom: 3, width: 150, height: 150 }}
                    />
                    <SemiTitle>{block.label}</SemiTitle>
                    <RichText>{block._rawText}</RichText>
                  </Flex>
                );
              case 'SanityTextBlock':
                return (
                  <Box key={block._key}>
                    <RichText>{block._rawText}</RichText>
                    <CallToAction {...block.callToAction} />
                  </Box>
                );
              case 'SanityTitleBlock':
                return (
                  <Box key={block._key}>
                    <Section.Title>{block.title}</Section.Title>
                    <SemiTitle>{block.subtitle}</SemiTitle>
                  </Box>
                );
              case 'SanityImageBlock':
                return <GridImage key={block._key} image={block.image} />;
              default:
                return <Text>Not supported</Text>;
            }
          })}
        </Grid>
        <Flex align="center" justify="center">
          <CallToAction
            {...callToAction}
            sx={{ marginTop: 5, marginBottom: 2 }}
          />
        </Flex>
      </Section.Body>
    </Section>
  );
}

function GridImage({ image, width = 600, ...rest }) {
  const height = undefined;
  return (
    <Image
      {...image}
      width={Array.isArray(width) ? width[width.length - 1] : width}
      // height={Array.isArray(height) ? height[height.length - 1] : height} // TODO height doesn't work due to a bug in sanity/image-url upstream library: https://github.com/sanity-io/image-url/issues/32
      alt="an image"
      sizes={`(max-width: 40em) ${Array.isArray(width) ? width[0] : width}px, ${
        Array.isArray(width) ? width[width.length - 1] : width
      }px`}
      sx={{
        objectFit: 'contain',
        // Always ensure the images are full-width
        width: '100%',
        height,
        borderRadius: 'default',
      }}
      {...rest}
    />
  );
}

export const fragment = graphql`
  fragment SanitySimpleGridSectionFragment on SanitySimpleGridSection {
    title
    callToAction {
      ...CallToAction
    }
    blocks {
      ... on SanityIconBlock {
        _key
        icon {
          ...ImageWithPreview
        }
        label
        _rawText
        callToAction {
          ...CallToAction
        }
      }
      ... on SanityTextBlock {
        _key
        _rawText
        callToAction {
          ...CallToAction
        }
      }
      ... on SanityTitleBlock {
        _key
        title
        subtitle
      }
      ... on SanityImageBlock {
        _key
        image {
          ...ImageWithPreview
        }
      }
    }
    style {
      ...SectionStyle
    }
  }
`;

import { graphql, useStaticQuery } from 'gatsby';

export default function useSiteMetadata() {
  /** @type {{site:{siteMetadata:{url: string; deployUrl: string; deployPrimeUrl: string}}}} */
  const data = useStaticQuery(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          url
          deployUrl
          deployPrimeUrl
        }
      }
    }
  `);
  return data.site.siteMetadata;
}

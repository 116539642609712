/** @jsx jsx */
import {
  Box,
  Container,
  Flex,
  Title,
  View,
} from '@bottlebooks/gatsby-design-system';
import { jsx } from 'theme-ui';
import SliderImage from './SliderImage';

export default function SimpleHeader({
  id = 'simpleHeader',
  imageUrl,
  title,
  subtitle,
  date,
  ...rest
}) {
  return (
    <View
      id={id}
      sx={{ position: 'relative', height: [287, 315, 350, 400] }}
      {...rest}
    >
      <SliderImage image={imageUrl}>
        <Container sx={{ display: 'block' }} {...rest}>
          <Flex
            sx={{
              alignItems: 'flex-end',
              height: '100%',
              paddingBottom: 4,
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <Title
                sx={{
                  color: 'white',
                  textTransform: 'uppercase',
                  fontWeight: 700,
                  fontSize: [36, 48, 62],
                  lineHeight: 1,
                }}
              >
                {title}
              </Title>
            </Box>
          </Flex>
        </Container>
      </SliderImage>
    </View>
  );
}
0;

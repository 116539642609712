import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Größentechnisch rangiert die Mosel mit ihren knapp 8.700 Hektar Rebfläche eher im deutschen Mittelfeld. Trotzdem geht es an der Mosel hoch hinaus: Nirgendwo auf der Welt gibt es mehr Steillagenweinberge als hier. Die Paraderebsorte für die Höhe ist ganz klar: der Riesling!`}</p>
    <p>{`Das Weinbaugebiet Mosel erstreckt sich entlang der Mosel zwischen Perl und Koblenz, an der Saar zwischen Serrig und Konz sowie an der Ruwer zwischen Riveris und dem Trierer Stadtteil Ruwer. Rund die Hälfte der Rebfläche befindet sich in Steil- und Terrassenlagen mit über 30 Grad Hangneigung. Doch die Winzerinnen und Winzer sind nicht nur Spezialisten für die Höhe, auch mit der Rebsorte Riesling kennen sie sich bestens aus: Die spät reifende, edle Weißweinsorte wächst hier auf mehr als der Hälfte der Rebfläche.`}</p>
    <p>{`Die geschützte Tallage macht die Region zu einer der wärmsten Klimazonen Deutschlands. Die steilen Schieferhänge über den Flüssen speichern am Tag die Sonnenwärme und geben sie nachts wieder ab. Die Wurzeln der Reben dringen metertief in die Böden ein, um sich mit Wasser und Mineralien zu versorgen. So können die Winzer aus den von Hand geernteten Trauben einzigartig feine, fruchtige Weine erzeugen. Neben dem Riesling ist Rivaner (Müller-Thurgau) die zweitwichtigste Rebsorte. An der Obermosel ist mit dem Elbling zudem eine historische Rebsorte heimisch, die möglicherweise schon vor 2.000 Jahren hier angebaut wurde.`}</p>
    <p>{`Das Anbaugebiet an Mosel, Saar und Ruwer gilt als älteste Weinregion Deutschlands. Die Römer brachten den Weinbau in großem Stil an die Mosel. Noch heute zeugen unzählige Funde, darunter mehrere Kelteranlagen aus römischer Zeit, von der großen Weinbautradition. Um 15 v. Chr. gründeten die Römer das heutige Trier, das im vierten Jahrhundert sogar zum Regierungssitz des Weströmischen Reiches wurde und zur größten Stadt nördlich der Alpen heranwuchs. Doch nicht nur historisch Interessierte, auch Naturliebhaberinnen und -liebhaber können an der Mosel viel entdecken. Durch das milde Klima sind hier entlang der Weinberge einzigartige Tiere und Pflanzen zu finden.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Die Ahr ist mit rund 560 Hektar Rebfläche eines der kleinsten Weinanbaugebiete Deutschlands. An den felsigen Steilhängen entlang des gleichnamigen Flusses gedeihen vor allem Rotweine.`}</p>
    <p>{`Im Norden von Rheinland-Pfalz, gelegen im Schutz der Eifel, liegt das kleine Anbaugebiet Ahr. Über 80% der angebauten Rebsorten sind hier rot und ein besonderes Augenmerk ist dabei auf den Spätburgunder gerichtet. Eine weitere rote Spezialität der Region ist der selten anzutreffende Frühburgunder.`}</p>
    <p>{`Das milde Klima beschert den Trauben im Ahrtal optimale Voraussetzungen. Die Lese ist in den zerklüfteten Felsspalten und extremen Steilhängen für die Winzerinnen und Winzer dafür umso beschwerlicher. Belohnt wird die beschwerliche Arbeit jedoch mit einer Spitzenqualität der Trauben.`}</p>
    <p>{`Das nördlichste geschlossene Rotweinanbaugebiet Deutschlands hat neben exzellenten Rotweinen noch einiges mehr zu bieten: herrliche Wanderwege, spannende Radstrecken, einmalige Wein-Events und kulturelle Highlights locken Aktive ins Ahrtal. Der malerische Rotweinwanderweg, der von Bad Bodendorf bis Altenahr über 35 Kilometer die Weinorte miteinander verbindet, bietet zu jeder Jahreszeit spektakuläre Aussichten. Viele Probierstuben und Straußwirtschaften entlang des Weges sorgen zudem für das leibliche Wohl.`}</p>
    <p>{`Die Seele baumeln lassen und genießen kann man auch auf den zahlreichen Weinfesten und weinkulturellen Events, sei es beim „Tag der offenen Weinkeller“ im April, beim Weinmarkt der Ahr an Pfingsten, oder bei den Weinwochenenden zur Weinlesezeit. Die abwechslungsreichen Veranstaltungen bieten das ganze Jahr viel Gelegenheit die Weine der Ahr kennenzulernen haben sowohl für Gourmet als auch für Weinkennerinnen und Weinkenner einiges an Genüssen zu bieten.`}</p>
    <p>{`Nach der Flutkatastrophe im vergangenen Jahr standen an der Ahr zahlreiche Weinerzeuger vor dem Nichts: überflutete Büros, Keller und Weinlager, zerstörte Maschinen. Und noch immer ist Hilfe dringend nötig. Auf der Website des Deutschen Weininstituts (DWI) finden Sie eine Übersicht verschiedenster Hilfsaktionen von Weinerzeugern, Handel, Verbänden und Organisationen, an denen Sie sich persönlich beteiligen können: `}<a parentName="p" {...{
        "href": "http://www.deutscheweine.de/aktuelles/hilfe-fuer-die-ahr"
      }}>{`www.deutscheweine.de/aktuelles/hilfe-fuer-die-ahr`}</a>{` Selbstverständlich benötigen die Flutopfer an der Ahr auch finanzielle Unterstützung. Aus diesem Grund wurde der Verein „Ahr - A wineregion needs Help for Rebuilding e.V.“ gegründet und ein Spendenkonto für die Ahrweinerzeuger eingerichtet: Ahr - A wineregion needs Help for Rebuilding e.V. KSK Ahrweiler IBAN: DE94 5775 1310 0000 3395 07 BIC: MALADE51AHR`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
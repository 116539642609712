import React from 'react';
import promotionalImageUrl from '../../assets/regions/customImages/DWI20_Reg_1700x1200_Baden.jpeg';
import backgroundImageUrl from '../../assets/regions/customImages/Baden1-scaled.jpeg';
import mapImage from '../../assets/regions/maps/Anbaugebiet-Baden-600x793.jpeg';
import mainImage from '../../assets/regions/mainImages/Baden2-scaled.jpeg';
import FormattedText from '../../cms-design-system/Elements/MDXFormattedText';
import Description from './baden.mdx';

export default {
  // Bottlebooks code
  id: 'de_baden',
  // Nice-to-read slug
  slug: 'baden',
  countryCode: 'de',
  name: 'Baden',
  country: 'Germany',
  mainImageUrl: mainImage,
  description: <FormattedText FormattedText={Description} />,
  mapImageUrl: mapImage,
  association: {
    name: 'Badischer Wein GmbH',
    street: 'Mauerbergstraße 32',
    postalCode: '76534',
    city: 'Baden-Baden',
    country: 'Germany',
    telephone: '+49 761  89 78 47 84',
    fax: '+49 761  89 76 63 96',
    website: 'https://www.badischerwein.de',
    // Simulating what we would need from GraphQL
    websiteText: 'www.badischerwein.de',
    email: 'mailto:info@badischerwein.de',
    emailText: 'info@badischerwein.de',
  },
  grapeVarieties: [
    'Silvaner',
    'Riesling',
    'Müller-Thurgau',
    'Gutedel',
    'Grauburgunder',
    'Spätburgunder',
  ],
  characteristics: [
    {
      title: 'Geographische Lage',
      description:
        'Vom Bodensee entlang der Oberrheinischen Tiefebene über die Badische Bergstraße und den Kraichgau bis Tauberfranken',
    },
    {
      title: 'Klima',
      description:
        'sonnig und warm, am Kaiserstuhl wärmste Region Deutschlands',
    },
    {
      title: 'Böden',
      description:
        'Moränenschotter am Bodensee, tertiäre Kalk-, Ton- und Mergelböden, riesige Lössablagerungen, vulkanische Böden am Kaiserstuhl und im Markgräflerland, Muschelkalk und Keuper im Kraichgau und Taubergrund',
    },
    {
      title: 'Rebfläche 2022',
      description: 'ca. 15.836 ha, 9 Bereiche, 16 Großlagen, 306 Einzellagen',
    },
    {
      title: 'Rebsorten 2019 [weiß 60%, rot 40%]',
      description:
        'Spätburgunder (33,7%), Müller-Thurgau (14,9%), Grauburgunder (13,8%), Weißer Burgunder (10%), Riesling, Gutedel, Silvaner, Traminer',
    },
  ],
  custom: {
    title: 'Baden … in Sonne und Burgunder',
    title2: '400 km Genuss unter südlicher Sonne',
    shortDescription:
      'Viele Sonnenstunden und landschaftliche Vielfalt – all das macht Baden zum Alleskönner mit einem besonderen Augenmerk auf den Burgunderrebsorten.',
    promotionalImageUrl,
    backgroundImageUrl,
    externalLink:
      'https://www.deutscheweine.de/tourismus/in-den-anbaugebieten/baden/',
  },
};

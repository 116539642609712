/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import { jsx } from 'theme-ui';

export default function PromotionIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 64 64"
      xmlSpace="preserve"
      sx={{ width: 50, height: 50, color: '#ec008c' }}
      {...rest}
    >
      <g transform="matrix(1.33333 0 0 -1.33333 0 64)">
        <g fillOpacity="1" stroke="none" transform="scale(.1)">
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M241.34 478.629c132.461 0 240-107.539 240-240 0-132.457-107.539-240.008-240-240.008s-240 107.551-240 240.008c0 132.461 107.539 240 240 240"
          ></path>
          <path
            fill="#fff"
            fillRule="nonzero"
            d="M118.352 335.988c0 7.481.187 18.711.57 22.641-.192.941-.942 1.5-2.063 1.691-7.679-4.308-20.78-8.8-36.308-11.789-.93-.75-.93-3.562.187-4.5l8.98-.929c8.423-.942 8.981-5.43 8.981-17.782v-59.332c0-17.957-.738-18.148-11.969-19.828l-5.058-.75c-1.313-1.121-1.313-4.871.379-5.43 8.98.379 17.59.559 27.129.559 8.242 0 17.031-.18 25.832-.559 1.679.559 1.679 4.309.367 5.43l-5.051.75c-11.226 1.68-11.976 1.871-11.976 19.828v70"
          ></path>
          <path
            fill="#fff"
            fillRule="nonzero"
            d="M202.191 360.32c-28.82 0-40.23-32.941-40.23-61.199 0-29.383 11.41-61.199 40.23-61.199 28.821 0 40.239 31.816 40.239 61.199 0 28.258-11.418 61.199-40.239 61.199zm0-7.492c16.289 0 18.161-36.117 18.161-53.707 0-17.59-1.872-53.711-18.161-53.711-16.281 0-18.152 36.121-18.152 53.711 0 17.59 1.871 53.707 18.152 53.707"
          ></path>
          <path
            fill="#fff"
            fillRule="nonzero"
            d="M373.43 310.539c-20.961 0-29.379-20.59-29.379-36.309 0-15.531 8.418-36.308 29.379-36.308 20.961 0 29.379 20.777 29.379 36.308 0 15.719-8.418 36.309-29.379 36.309zm0-6.367c11.789 0 13.66-20.211 13.66-29.942 0-9.73-1.871-29.941-13.66-29.941s-13.66 20.211-13.66 29.941c0 9.731 1.871 29.942 13.66 29.942zm-84.59 56.148c-20.961 0-29.379-20.59-29.379-36.308 0-15.532 8.418-36.313 29.379-36.313 20.961 0 29.379 20.781 29.379 36.313 0 15.718-8.418 36.308-29.379 36.308zm0-6.371c11.789 0 13.66-20.211 13.66-29.937 0-9.731-1.871-29.942-13.66-29.942s-13.66 20.211-13.66 29.942c0 9.726 1.871 29.937 13.66 29.937zm82.719 4.871c-.938 2.239-4.68 4.301-6.93 3.739l-75.598-125.387c.93-2.434 3.547-4.301 6.547-4.113l75.981 125.761"
          ></path>
          <path
            fill="#fff"
            fillRule="nonzero"
            d="M89.77 147.441c10.628 0 21 5.118 21 16.821 0 10.636-7.68 15.207-14.942 18.707-5.117 2.429-9.02 5.25-9.02 9.16 0 3.902 3.231 7 7.544 7 5.238 0 9.546-5.117 11.57-11.309.937-.808 3.226-.398 3.629.668 0 6.063-.672 10.231-2.012 13.063-1.891 1.07-7 2.687-12.52 2.687-11.167 0-20.19-6.187-20.058-16.687 0-8.61 7.27-13.59 14.27-16.961 4.71-2.289 9.148-5.52 9.148-10.5 0-4.711-3.36-7.531-7.527-7.531-7.141 0-12.122 7.132-14.543 14.671-.938.942-2.957.801-3.77-.269-.27-5.789.54-12.66 1.89-15.211 4.301-3.23 9.821-4.309 15.34-4.309"
          ></path>
          <path
            fill="#fff"
            fillRule="nonzero"
            d="M124.629 131.02c0-9.961-.539-11.579-5.117-12.11l-3.492-.41c-.95-.941-.809-3.359.261-3.898 5.391.269 10.641.398 15.078.398 4.981 0 10.231-.129 17.231-.398 1.07.539 1.211 2.957.269 3.898l-4.847.539c-4.711.539-5.25 2.02-5.25 11.981v13.191c0 2.687.808 3.5 2.019 3.769 1.207-.269 4.309-.539 5.918-.539 19.789 0 32.981 13.188 32.981 31.899 0 13.859-8.481 24.898-23.149 24.898-7 0-14.941-4.976-16.691-5.789-.668.141-1.078.942-1.078 1.75 0 1.211.269 2.832.668 5.25-.258.813-1.071 1.352-2.149 1.352-4.043-2.692-12.109-6.461-17.5-7.942-.672-.679-.672-2.289-.133-3.097l1.614-1.082c3.5-2.282 3.367-3.5 3.367-9.551zm14.133 53.57c0 5.648.41 7.941 1.476 9.148 1.621 1.621 4.172 2.692 7.274 2.692 10.5 0 17.09-8.75 17.09-21.532 0-12.378-5.114-22.078-14.403-22.078-2.019 0-5.379 1.219-7.129 2.7-2.832 2.421-4.308 4.441-4.308 8.609v20.461"
          ></path>
          <path
            fill="#fff"
            fillRule="nonzero"
            d="M230.961 187.422c.539 11.84-6.063 16.816-16.691 16.816-6.872 0-16.559-4.578-20.731-8.476-4.437-4.172-5.25-7-5.25-8.614 0-1.218.813-2.16 2.563-2.16 2.957 0 6.726 1.211 8.609 2.434 1.48.937 2.289 2.277 2.559 4.84.539 3.898 3.371 5.918 7.128 5.918 6.602 0 8.481-5.918 8.211-12.782l-.129-3.5c0-1.347-.402-2.558-3.101-3.5-4.981-1.886-10.09-3.769-14.801-5.386-9.289-3.231-11.168-7.672-11.168-12.512 0-6.73 4.57-13.059 13.731-13.059 4.839 0 8.879 1.879 11.437 3.5 2.024 1.207 2.961 1.879 3.633 1.879.668 0 1.211-.672 2.148-1.609 2.563-2.832 6.332-3.77 9.692-3.77 4.179 0 10.769 2.149 13.461 5.918.41 1.211-.532 2.692-1.211 2.692-.403 0-2.289-1.481-4.571-1.481-4.851 0-6.871 4.039-6.46 11.852zm-14.402-26.121c-.137-3.231-1.618-6.461-6.457-6.461-5.122 0-7.543 4.308-7.543 7.808 0 5.793 3.5 7.672 7.269 9.024 2.422.937 4.844 1.609 5.922 1.609.672 0 1.078-.801 1.078-2.293l-.269-9.687"
          ></path>
          <path
            fill="#fff"
            fillRule="nonzero"
            d="M252.359 165.34c0-9.961-.539-11.301-5.121-12.11l-2.418-.41c-.941-.941-.808-3.359.27-3.898 5.109.269 9.558.398 14 .398 4.98 0 9.551-.129 17.629-.398 1.082.539 1.211 2.957.269 3.898l-5.25.539c-4.707.539-5.25 2.02-5.25 11.981v18.441c0 6.598.813 9.688 3.903 9.688.808 0 3.097-.27 6.461-2.559 1.078-.672 2.289-1.07 3.636-1.07 3.633 0 7.543 2.558 7.543 7.801 0 3.5-2.703 6.597-7.41 6.597-4.57 0-9.012-4.039-13.191-7-.801 0-1.34.672-1.34 1.621 0 1.211.129 3.5.269 6.051-.269.809-1.078 1.211-1.89 1.211-4.039-2.422-11.168-5.383-16.957-7.262-.543-.679-.543-2.558-.133-3.097l1.609-.942c3.371-2.019 3.371-3.64 3.371-9.691V165.34"
          ></path>
          <path
            fill="#fff"
            fillRule="nonzero"
            d="M327.461 181.219c5.391 0 8.48.543 9.289 1.75.539 1.082.672 1.89.672 3.101 0 11.711-10.231 18.168-20.192 18.168-18.031 0-28.402-14.668-28.402-29.879 0-15.339 10.371-26.918 25.981-26.918 8.211 0 18.171 4.039 22.75 14.809-.137 1.211-1.618 2.551-2.969 2.148-3.09-4.976-7.531-6.726-12.918-6.726-11.973 0-20.32 9.687-20.32 21.797 0 1.621.269 1.75 2.828 1.75zm-23.152 5.39c-1.61 0-1.879.403-1.879.942 0 2.828 4.172 11.578 13.191 11.578 5.777 0 8.879-3.91 8.879-8.75 0-1.219-.539-2.289-1.352-2.828-.937-.539-2.687-.942-6.589-.942h-12.25"
          ></path>
          <path
            fill="#fff"
            fillRule="nonzero"
            d="M365.691 183.379c0 4.98.129 6.191 1.879 8.609 1.352 2.024 4.711 4.172 8.879 4.172 8.082 0 10.77-5.379 10.77-12.238V165.34c0-9.961-.539-11.57-5.11-12.25l-2.019-.27c-.942-.941-.809-3.359.269-3.898 4.711.269 9.282.398 13.731.398 4.98 0 9.551-.129 15.199-.398 1.082.539 1.223 2.957.27 3.898l-3.09.41c-4.578.54-5.117 2.149-5.117 12.11v20.859c0 10.5-5.114 18.039-16.012 18.039-8.352 0-12.121-3.226-18.442-6.726-.808.129-1.207.398-1.207 1.347 0 1.071 0 3.629.27 6.192-.27.539-.813 1.07-1.75 1.07-4.313-2.422-11.711-5.652-17.5-7.262-.539-.679-.539-2.558-.141-3.097l1.621-.942c3.36-2.019 3.36-3.64 3.36-9.691V165.34c0-9.961-.531-11.442-5.11-12.11l-2.961-.41c-.941-.941-.808-3.359.27-3.898 5.648.269 10.23.398 14.531.398 4.981 0 9.559-.129 14.27-.398 1.078.539 1.211 2.957.269 3.898l-2.019.27c-4.582.68-5.11 2.289-5.11 12.25v18.039"
          ></path>
        </g>
      </g>
    </svg>
  );
}

import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Vom 11. bis 20. März 2022 geht es erneut auf eine köstliche Entdeckungsreise durch die 13 deutschen Weinregionen: Bestellen Sie sich spannende Weinpakete direkt nach Hause, probieren Sie deutsche Weine in 22 verschiedenen interaktiven Online-Verkostungen und lernen Sie die 88 Weingüter dahinter kennen. Genießen Sie zehn Tage lang live und ganz bequem von Ihrem Wohnzimmer aus.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Mit seiner Fläche von rund 840 Hektar gehört Saale-Unstrut eher zu den kleineren Weinregionen in Deutschland. Was die geographische Lage angeht, so liegt das Anbaugebiet jedoch ganz weit vorn – oder besser gesagt: weit oben. Saale-Unstrut ist die nördlichste Weinregion in Deutschland.`}</p>
    <p>{`Die Bezeichnung “hoch oben im Norden” trifft aus der Sicht der anderen Weinregionen für Saale-Unstrut zweifelsfrei zu. Die Region liegt auf dem 51. Breitengrad nördlicher Breite und ist damit das nördlichste Qualitätsweinanbaugebiet Deutschlands. Die Weinberge liegen in den namensgebenden Flusstälern von Saale und Unstrut, der größte Teil befindet sich im Süden Sachsen-Anhalts sowie einige Hektar in Thüringen und Brandenburg.`}</p>
    <p>{`Trotz der nördlichen Lage, ist Saale-Unstrut eine der niederschlagärmsten Weinregionen in Deutschland. In den Flusstälern bilden sogenannte Wärmeinseln ein besonders mildes Mikroklima, was vor regionalen Winter- und Spätfrösten schützt. Der ausgewogene Mix von Sonne und Kühle sorgt insgesamt für feingliedrige und spritzige Tropfen. Besonders die Weine der Rebsorte Müller-Thurgau entwickeln in der Region eine erstaunliche Raffinesse.`}</p>
    <p>{`Insgesamt laden rund 30 Rebsorten zum Probieren und Vergleichen ein. Weißweine von Weißburgunder, Silvaner und Riesling gehören zu den Klassikern der Region, doch auch Rotweinsorten, vor allem Portugieser, Dornfelder, Spätburgunder und Zweigelt finden sich hier. Die Weine der Region sind häufig Raritäten, die schnell ausverkauft sind.`}</p>
    <p>{`In der Region Saale-Unstrut finden sich jedoch nicht nur edle Weine, auch idyllische Landschaften können bei einem Besuch erkundet werden: Steilterrassen, uralte Trockenmauern und Weinberghäuschen, dazwischen Streuobstwiesen und Flussauen – auf dem sehr gut ausgebauten Rad-, Wander- und Wasserwegenetz macht die Erkundung des Anbaugebiets und seiner Weine, der Weingüter, Straußwirtschaften und Gutsschänken besonders viel Spaß. Entlang der Weinstraße Saale-Unstrut verlaufen die “Straße der Romanik” sowie die “Himmelswege”. Burgen und Schlösser, bedeutende Bauwerke, sowie mystische Stätten erzählen von der kulturellen Geschichte des Landes. Kultur und Geschichte, Natur, Romantik und Wein sind in Saale-Unstrut ganz selbstverständlich vereint.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
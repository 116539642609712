/** @jsx jsx */
// @ts-check
import { Box, Flex } from '@bottlebooks/gatsby-theme-base';
import BrandLogo from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandLogo';
import OfferingBadge from '@bottlebooks/gatsby-theme-event/src/components/Brand/OfferingBadge';
import ExhibitorHeader from '@bottlebooks/gatsby-theme-event/src/components/Exhibitor/ExhibitorHeader';
import ToggleFavorite from '@bottlebooks/gatsby-theme-event/src/components/Favorites/ToggleFavorite';
import ListRow from '@bottlebooks/gatsby-theme-event/src/components/List/ListRow';
import ListRowBody from '@bottlebooks/gatsby-theme-event/src/components/List/ListRowBody';
import { useFavorite } from '@bottlebooks/gatsby-theme-event/src/components/TastingNote/useFavorite';
import { jsx } from 'theme-ui';
import CoverImage from '../CoverImage';
import PromotionIcon from '../../../../components/icons/PromotionIcon';

/**
 *
 * @param {object} props
 * @param {object} props.exhibitor
 * @param {string} [props.to]
 * @param {React.ReactNode} [props.children]
 * @returns
 */
export default function ExhibitorListRow({ exhibitor, children, ...rest }) {
  // TODO use registrationId: exhibitor.registrationId,
  const favorite = useFavorite(
    exhibitor && { type: 'registration', id: exhibitor.exhibitorId }
  );
  const hasBackground = Boolean(exhibitor?.mainImage);
  return (
    <Box sx={{ position: 'relative' }}>
      <ListRow
        {...rest}
        onDark={hasBackground}
        sx={{
          minHeight: 200,
          boxSizing: 'border-box',
          // Only add hover to links. The `&` is replaced by the class name automatically.
          'a[href]&:hover': {
            '  .bottlebooks-bgimg': {
              transform: 'scale(1.01)',
              '::after': {
                backgroundColor: 'overlaySecondary',
              },
            },
          },
        }}
      >
        {exhibitor?.mainImage && (
          <CoverImage
            {...exhibitor.mainImage}
            hotspot={
              exhibitor.mainImage.hotspot || {
                x: 0.5,
                y: 0.35,
                width: 0.2,
                height: 0.2,
              }
            } // Default to hotspot at the upper 35% of the image.
            alt={exhibitor.name}
            sx={{ position: 'absolute', transition: 'transform 0.7s ease-out' }}
            className="bottlebooks-bgimg"
            sizes="(max-width: 40em) 100vw, 650px"
            width={650}
            height={200}
            withOverlay
          />
        )}
        <BrandLogo
          brand={exhibitor}
          onDark={hasBackground}
          sx={{
            margin: 3,
            marginRight: 0,
            position: 'relative',
          }}
        />
        <ListRowBody
          sx={{ position: 'relative', marginBottom: 3, marginRight: 5 }}
        >
          <ExhibitorHeader exhibitor={exhibitor} onDark={hasBackground} />
        </ListRowBody>

        <ToggleFavorite
          sx={{ position: 'absolute', top: 3, right: 3 }}
          isToggled={favorite.isSet}
          variant="small"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            favorite.toggle();
          }}
        />
      </ListRow>
      <Flex
        direction="column"
        sx={(theme) => ({
          position: 'absolute',
          bottom: 3,
          right: 3,
          color: hasBackground ? 'onDark' : 'inherit',
          textShadow: hasBackground
            ? `1px 1px ${theme.colors?.black}, 0 0 20px ${theme.colors?.black}`
            : undefined,
        })}
      >
        <BrandFeatures brand={exhibitor} />
        {exhibitor.offersDiscount && <PromotionIcon />}
        {children}
      </Flex>
    </Box>
  );
}

export function BrandFeatures({ brand }) {
  if (!brand.offeringTypes?.length) return null;
  return (
    <Flex
      justify="flex-end"
      align="flex-end"
      gap={2}
      sx={{
        width: '100%',
        paddingRight: 2,
        paddingBottom: 2,
        position: 'relative', // Needed to stay above image backgrounds
        marginLeft: 'auto',
      }}
    >
      {brand.offeringTypes?.map((type) => (
        <OfferingBadge key={type} variant="onPrimary" type={type} />
      ))}
    </Flex>
  );
}

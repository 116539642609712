import { Box, Grid, Link, P } from '@bottlebooks/gatsby-design-system';
import React from 'react';
import bannerImage from '../../assets/regions/mainImages/Ahr_Weinregionen__13_-scaled.jpg';
import { Regions, Section } from '../../cms-design-system';

export default function regionsPage({ regions }) {
  return {
    sections: [
      {
        component: 'Slider',
        data: {
          id: 'banner',
          imageUrl: bannerImage,
          title: 'There’s More to German Wine Than Riesling',
          subtitle: 'The New York Times',
        },
      },
      {
        component: 'Section',
        data: {
          children: (
            <Section.Wrapper>
              <Section.Title sx={{ marginBottom: 3 }}>
                Entdecken Sie 13 Weinregionen und deren Weine für sich!
              </Section.Title>
              <Section.Body>
                <Grid columns={[1, 1, 2, 2]} gap={3}>
                  <Box>
                    <P>
                      Urlaub fast vor der eigenen Haustür wird für viele
                      Deutsche immer attraktiver. Insbesondere in den
                      Weinregionen gehen reizvolle Landschaften, Kultur,
                      Gastfreundschaft und Genuss eine wunderbare Verbindung
                      ein. Natürlich kommen auch moderne Freizeitaktivitäten und
                      Aktivurlaube nicht zu kurz.
                    </P>
                    <P>
                      Ob Wasserski- und Drachenflugangebote an der Mosel,
                      Kajak-Touren auf dem Main, Freeclimbing in Württemberg
                      oder Motorroller-Touren in der Pfalz, in deutschen
                      Weinregionen ist einiges geboten. Wer Entspannung sucht,
                      findet unter zahlreichen Wein- und Wellness-Angeboten
                      bestimmt das Richtige. Weinstraßen führen die Besucher in
                      vielen Weinbaugebieten an die schönsten Orte der Region.
                      Ganz hervorragend lassen sich die Weinlande auch auf dem
                      Fahrrad erkunden. Hier bieten sich selbstverständlich
                      Touren entlang der Flüsse an, die sich durch die meisten
                      Weinbaugebiete schlängeln.
                    </P>
                  </Box>
                  <Box>
                    <P>
                      Die Touristeninformationen in vielen Gebieten halten
                      spezielle Radwanderkarten bereit, inklusive
                      ausgearbeiteter Tourenvorschläge und Pauschalarrangements.
                    </P>
                    <P>
                      Zur Rast laden die typischen Straußwirtschaften ein. Hier
                      bewirten die Winzer*innen ihre Gäste mit preiswertem und
                      regionaltypischem Essen, dazu wird Wein aus eigenem Anbau
                      ausgeschenkt.
                    </P>
                    <P>
                      Der krönende Abschluss eines erlebnisreichen Tages in den
                      Weinregionen ist die Einkehr und Übernachtung direkt vor
                      Ort beim Weingut. Hier gehen Genuss und Gastfreundschaft
                      Hand-in-Hand.
                    </P>
                    <P>
                      Wir wünschen genüssliche Stunden dort, wo der Wein in
                      Deutschland wächst. Mehr Informationen zu den Regionen
                      finden Sie auch auf{' '}
                      <Link href={'https://deutscheweine.de/tourismus'}>
                        deutscheweine.de/tourismus
                      </Link>
                    </P>
                  </Box>
                </Grid>
              </Section.Body>
            </Section.Wrapper>
          ),
        },
      },
      {
        component: 'Section',
        data: {
          // variant: 'dark',
          children: (
            <Section.Wrapper>
              <Section.Title sx={{ marginBottom: 3 }}>
                Regionen nach Rebsorten
              </Section.Title>
              <Section.Body>
                <Regions regions={regions} />
              </Section.Body>
            </Section.Wrapper>
          ),
        },
      },
    ],
  };
}

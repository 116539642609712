import React from 'react';
import promotionalImageUrl from '../../assets/regions/customImages/DWI20_Reg_1700x1200_Mosel.jpeg';
import backgroundImageUrl from '../../assets/regions/customImages/Mosel2-scaled.jpeg';
import mapImage from '../../assets/regions/maps/MoselMap.png';
import mainImage from '../../assets/regions/mainImages/Mosel1-scaled.jpeg';
import FormattedText from '../../cms-design-system/Elements/MDXFormattedText';
import Description from './mosel.mdx';

export default {
  // Bottlebooks code
  id: 'de_mosel',
  // Nice-to-read slug
  slug: 'mosel',
  countryCode: 'de',
  name: 'Mosel',
  country: 'Germany',
  mainImageUrl: mainImage,
  description: <FormattedText FormattedText={Description} />,
  mapImageUrl: mapImage,
  association: {
    name: 'Mosel-Wein e.V.',
    street: 'Gartenfeldstraße 12a',
    postalCode: '54295',
    city: 'Trier',
    country: 'Germany',
    telephone: '+49 6 51 71 02 80',
    fax: '+49 6 51 71028-20',
    website: 'https://www.weinland-mosel.de ',
    // Simulating what we would need from GraphQL
    websiteText: 'www.weinland-mosel.de ',
    email: 'mailto:info@weinland-mosel.de',
    emailText: 'info@weinland-mosel.de',
  },
  grapeVarieties: [
    'Riesling',
    'Müller-Thurgau',
    'Elbling',
    'Weißburgunder',
    'Spätburgunder',
  ],
  characteristics: [
    {
      title: 'Geographische Lage',
      description:
        'Zwischen Hunsrück und Eifel im Rheinischen Schiefergebirge; entlang der Mosel und ihren Nebenflüssen Saar und Ruwer',
    },
    {
      title: 'Klima',
      description:
        'Optimale Erwärmung und optimales Niederschlagsverhältnis in den Steilhängen und Tälern',
    },
    {
      title: 'Böden',
      description:
        'An der Obermosel Muschelkalk und Keuper; in den Tälern von Saar und Ruwer sowie an der Mittelmosel Devon-Schiefer, südlich von Zell weiche Tonschiefer und kieselsäurereiche Grauwacken; in Tallagen mit Schotter-, Kies- und Sandablagerungen',
    },
    {
      title: 'Rebfläche 2022',
      description: '8700 ha, 6 Bereiche, 18 Großlagen und 524 Einzellagen',
    },
    {
      title: 'Rebsorten 2019 [weiß 90.6%, rot 9.4%]',
      description:
        'Riesling (62.2%), Müller-Thurgau (10.2%), Elbling (5.3%), Pinot Blanc (4.1%) and Pinot Noir (4.6%).',
    },
  ],
  custom: {
    title: 'Mosel - Römer, Riesling, steile Hänge',
    title2: 'Riesling aus der Höhe',
    shortDescription:
      'Das älteste Anbaugebiet Deutschlands erstreckt sich entlang der Flüsse Mosel, Saar und Ruwer. In seinen extremen Steillagenweinbergen ist vor allem der Riesling beheimatet.',
    promotionalImageUrl,
    backgroundImageUrl,
    externalLink:
      'https://www.deutscheweine.de/tourismus/in-den-anbaugebieten/mosel/',
  },
};

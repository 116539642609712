import dontDrinkAndDriveLogo from '../../assets/dontDrinkAndDriveLogo.jpg';
import seltersLogo from '../../assets/seltersLogo.jpg';
import vinumLogo from '../../assets/vinumLogo.png';
import wineInModerationLogo from '../../assets/wineInModerationLogo.png';
import zwieselLogo from '../../assets/zwieselLogo.png';
import weinPlusLogo from '../../assets/weinPlusLogo.png';

/** @typedef {Array<Sponsor>}  */
export default [
  {
    name: 'Wein-Plus',
    url: 'https://www.wein.plus/de',
    imageUrl: weinPlusLogo,
  },
  {
    name: "Don't Drink and Drive",
    url: 'https://ddad.de',
    imageUrl: dontDrinkAndDriveLogo,
  },
  {
    name: 'Wine in Moderation',
    url: 'https://www.wineinmoderation.eu',
    imageUrl: wineInModerationLogo,
  },
  { name: 'Selters', url: 'https://www.selters.de', imageUrl: seltersLogo },
  { name: 'Vinum', url: 'https://www.vinum.eu/de', imageUrl: vinumLogo },
  {
    name: 'Zwiesel Glass',
    url: 'https://www.zwiesel-glas.com',
    imageUrl: zwieselLogo,
  },
];

/**
 * @typedef Sponsor
 * @property {string} name
 * @property {string} url
 * @property {string} imageUrl
 */

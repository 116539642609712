/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import { Flex, Stack, Text } from '@bottlebooks/gatsby-theme-base';
import { jsx } from 'theme-ui';
import {
  ProductAlcohol,
  ProductBottleSize,
  ProductName,
  ProductRegion,
  ProductResidualSugar,
  ProductTotalAcidity,
} from '../@bottlebooks/gatsby-theme-event/components/Product/ProductHeader';
import ProductImage from '../@bottlebooks/gatsby-theme-event/components/Product/ProductImage';

export default function ProductCard({ product, children, ...rest }) {
  return (
    <Flex direction={['column', 'row']}>
      <ProductImage
        product={product}
        size="xlarge"
        width={250}
        sx={{
          flexBasis: 300,
          order: [0, 1],
          marginLeft: [0, 3],
          marginBottom: [3, 0],
        }}
      />
      <Stack sx={{ flex: '1 1 50%' }}>
        <ProductName variant="large" product={product} />
        <ProductRegion product={product} />
        {children}
      </Stack>
    </Flex>
  );
}

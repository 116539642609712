import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`An der Ahr stehen zahlreiche Weinerzeuger vor dem Nichts: überflutete Büros, Keller und Weinlager, zerstörte Maschinen. Auf der Website des Deutschen Weininstituts (DWI) finden Sie eine Übersicht verschiedenster Hilfsaktionen von Weinerzeugern, Handel, Verbänden und Organisationen, an denen Sie sich persönlich beteiligen können: `}<a parentName="p" {...{
        "href": "https://www.deutscheweine.de/aktuelles/hilfe-fuer-die-ahr/"
      }}>{`Liste der Ahr-Hilfsaktionen`}</a></p>
    <p>{`Selbstverständlich benötigen die Flutopfer an der Ahr auch finanzielle Unterstützung. Aus diesem Grund wurde der Verein „Ahr – A wineregion needs Help for Rebuilding e.V.“ gegründet und ein Spendenkonto für die Ahrweinerzeuger eingerichtet:`}</p>
    <p>{`Ahr – A wineregion needs Help for Rebuilding e.V.
KSK Ahrweiler
IBAN: DE94 5775 1310 0000 3395 07
BIC: MALADE51AHR`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
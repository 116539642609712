import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Geführt werden die ca. einstündigen Verkostungen von der `}<a parentName="p" {...{
        "href": "/packages"
      }}>{`Deutschen Weinkönigin Sina Erdrich`}</a>{`, der Deutschen Weinprinzessin `}<a parentName="p" {...{
        "href": "/packages"
      }}>{`Saskia Teucke`}</a>{`, den Masters of Wine `}<a parentName="p" {...{
        "href": "/packages"
      }}>{`Romana Echensperger MW`}</a>{`, `}<a parentName="p" {...{
        "href": "/packages"
      }}>{`Konstantin Baum MW`}</a>{` und `}<a parentName="p" {...{
        "href": "/packages"
      }}>{`Thomas Curtius MW`}</a>{`.`}</p>
    <p>{`Ebenso wieder mit dabei `}<a parentName="p" {...{
        "href": "/packages"
      }}>{`Toni Askitis`}</a>{`, von `}<a parentName="p" {...{
        "href": "/packages"
      }}>{`#asktoni`}</a>{`, und Spielmacher `}<a parentName="p" {...{
        "href": "/packages"
      }}>{`Christoph Friedrich`}</a>{` der mit dem interaktiven WeinTour-Live-Quiz für den Entertainment-Faktor sorgt.`}</p>
    <p>{`TV-Moderator `}<a parentName="p" {...{
        "href": "/packages"
      }}>{`Manuel Andrack`}</a>{` widmet sich in seinen Online-Verkostungen speziell dem “Wandern in Deutschlands Weinregionen”. Folgen Sie dem renommierten Wanderbuch-Autor auf die schönsten Wanderwege, erfahren Sie, wo sich die tollsten Weinsichten verbergen und bei welchem Weingut man unbedingt Rast machen sollte.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
/** @jsx jsx */
import { graphql, useStaticQuery } from 'gatsby';
import { jsx } from 'theme-ui';
import * as cms from '../../cms';

/**
 * A ReferencedSection allows advanced/custom sections to be mixed in sections
 * designed with Bottlebooks CMS building blocks.
 *
 * At the moment, these are sections where the customer has a particular requirement
 * that would significantly overcomplicate the DSL, and which would be easier to
 * implement in code.
 *
 * In the future, this could be how we blend in advanced standard Bottlebooks
 * components such integrating a list of regions on to the landing page.
 *
 * @param {object} props
 * @param {string} props.sectionId
 * @returns
 */
export default function ReferencedSection({ sectionId }) {
  const {
    event: { eventId },
  } = useStaticQuery(graphql`
    {
      event {
        eventId
      }
    }
  `);
  const Section = cms[`event_${eventId}`].customSections?.[sectionId];
  return <Section />;
}

export const fragment = graphql`
  fragment SanityReferencedSectionFragment on SanityReferencedSection {
    sectionId
  }
`;

/** @jsx jsx */
import {
  Box,
  ChevronDownIcon,
  ChevronRightIcon,
  Flex,
  Text,
} from '@bottlebooks/gatsby-design-system';
import { Button } from '@bottlebooks/gatsby-theme-base';
import { graphql } from 'gatsby';
import React from 'react';
import { jsx } from 'theme-ui';
import { RichText } from '..';
import Section from '../Layout/Section';

export default function Faqs({
  id = 'faqs',
  title,
  questions,
  variant = 'light',
  style,
  ...rest
}) {
  return (
    <Section variant={variant} {...style} {...rest}>
      <Section.Title>{title}</Section.Title>
      {questions.map((question, index) => {
        return (
          <Question
            key={`question_${index}`}
            sx={{ marginBottom: 2 }}
            {...question}
          />
        );
      })}
    </Section>
  );
}

function Question({ question, answer, ...rest }) {
  const [showAnswer, setShowAnswer] = React.useState(false);
  return (
    <Box sx={{ borderBottom: '1px solid #efefef', paddingBottom: 1 }} {...rest}>
      <Button
        variant="text"
        onClick={() => setShowAnswer(!showAnswer)}
        sx={{
          width: '100%',
          padding: 0,
          ':hover .chevron': {
            backgroundColor: (theme) => `${theme.colors?.secondary}`,
          },
        }}
      >
        <Flex align="center">
          <Flex
            className="chevron"
            align="center"
            justify="center"
            sx={{
              marginRight: 2,
              color: 'white',
              borderRadius: 100,
              width: 20,
              height: 20,
              transition: 'all 0.3s ease-out',
              backgroundColor: (theme) => `${theme.colors?.primary}`,
            }}
          >
            {showAnswer ? <ChevronDownIcon /> : <ChevronRightIcon />}
          </Flex>
          <Text sx={{ fontWeight: 600 }}>{question}</Text>
        </Flex>
      </Button>

      <Flex
        sx={{
          maxHeight: showAnswer ? '500px' : 0,
          transition: 'max-height 0.3s ease-in-out',
          overflowY: 'hidden',
        }}
      >
        <Box sx={{ marginRight: 2, width: 20, flexShrink: 0 }} />
        <RichText>{answer}</RichText>
      </Flex>
    </Box>
  );
}

export const fragment = graphql`
  fragment SanityFaqSectionFragment on SanityFaqSection {
    title
    questions {
      question
      answer: _rawAnswer
    }
    style {
      ...SectionStyle
    }
  }
`;

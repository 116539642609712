import React from 'react';
import promotionalImageUrl from '../../assets/regions/customImages/DWI20_Reg_1700x1200_Nahe.jpeg';
import backgroundImageUrl from '../../assets/regions/customImages/Nahe2-scaled.jpeg';
import mapImage from '../../assets/regions/maps/Anbaugebiet-Nahe-800x946.jpeg';
import mainImage from '../../assets/regions/mainImages/Nahe1-scaled.jpeg';
import FormattedText from '../../cms-design-system/Elements/MDXFormattedText';
import Description from './nahe.mdx';

export default {
  // Bottlebooks code
  id: 'de_nahe',
  // Nice-to-read slug
  slug: 'nahe',
  countryCode: 'de',
  name: 'Nahe',
  country: 'Germany',
  mainImageUrl: mainImage,
  description: <FormattedText FormattedText={Description} />,
  mapImageUrl: mapImage,
  association: {
    name: 'Weinland Nahe e.V.',
    street: 'Burgenlandstr. 7',
    postalCode: '55543',
    city: 'Bad Kreuznach',
    country: 'Germany',
    telephone: '+49 671 83 40 50',
    fax: '+49 671 83 40 52 5',
    website: 'https://www.weinland-nahe.de',
    // Simulating what we would need from GraphQL
    websiteText: 'www.weinland-nahe.de',
    email: 'mailto:info@weinland-nahe.de',
    emailText: 'info@weinland-nahe.de',
  },
  grapeVarieties: [
    'Riesling',
    'Müller-Thurgau',
    'Grauburgunder',
    'Weißburgunder',
    'Dornfelder',
    'Spätburgunder',
  ],
  characteristics: [
    {
      title: 'Geographische Lage',
      description:
        'Weinbau entlang des Flusses Nahe am Fuß des Hunsrücks von Martinstein bis Bingen und in den Seitentälern von Guldenbach, Gräfenbach, Glan, Trollbach, Ellerbach und Alsenz, eingebettet im Naturpark Soonwald-Nahe',
    },
    {
      title: 'Klima',
      description:
        'ausgeglichen, mild, frostarm und regenarmViele Sonnentage und windgeschützte Lagen; der Rhein wirkt als Wärmespeicher',
    },
    {
      title: 'Böden',
      description:
        'Quarzit- und Schieferböden an der unteren Nahe; Porphyr, Melaphyr und Buntsandstein an der mittleren Nahe; Verwitterungsböden und Tonüberlagerungen aus Sandstein, Löss und Lehm bei Bad Kreuznach',
    },
    {
      title: 'Rebfläche 2022',
      description: '4.239 ha, 6 Großlagen, 310 Einzellagen',
    },
    {
      title: 'Rebsorten 2019 [weiß 84.8%, rot 15.2%]',
      description:
        'Riesling (29%), Müller-Thurgau (12%), Pinot Gris (8.2%), Pinot Blanc (7.4%), Dornfelder (9.7%) und Spätburgunder (6.7%)',
    },
  ],
  custom: {
    title: 'Nahe - Sanfter Fluss & schroffer Fels',
    title2: 'Facettenreiches Juwel im Südwesten',
    shortDescription:
      'Kein anderes Anbaugebiet bietet so viele verschiedene Bodentypen auf so kleinem Raum.',
    promotionalImageUrl,
    backgroundImageUrl,
    externalLink:
      'https://www.deutscheweine.de/tourismus/in-den-anbaugebieten/nahe/',
  },
};

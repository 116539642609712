import React from 'react';
import promotionalImageUrl from '../../assets/regions/customImages/DWI20_Reg_1700x1200_Mittelrhein.jpeg';
import backgroundImageUrl from '../../assets/regions/customImages/Mittelrhein2-scaled.jpeg';
import mapImage from '../../assets/regions/maps/Anbaugebiet-Mittelrhein-600x601.jpeg';
import mainImage from '../../assets/regions/mainImages/Mittelrhein1-scaled.jpeg';
import FormattedText from '../../cms-design-system/Elements/MDXFormattedText';
import Description from './mittelrhein.mdx';

export default {
  // Bottlebooks code
  id: 'de_mittelrhein',
  // Nice-to-read slug
  slug: 'mittelrhein',
  countryCode: 'de',
  name: 'Mittelrhein',
  country: 'Germany',
  mainImageUrl: mainImage,
  description: <FormattedText FormattedText={Description} />,
  mapImageUrl: mapImage,
  association: {
    name: 'Mittelrhein-Wein e.V.',
    street: 'Dolkstraße 19',
    postalCode: '56346',
    city: 'St. Goarshausen',
    country: 'Germany',
    telephone: '+49 6771-9599-103',
    fax: '+49 6771-599-607',
    website: 'https://www.mittelrhein-wein.com',
    // Simulating what we would need from GraphQL
    websiteText: 'www.mittelrhein-wein.com',
    email: 'mailto:info@mittelrhein-wein.com',
    emailText: 'info@mittelrhein-wein.com',
  },
  grapeVarieties: ['Riesling', 'Müller-Thurgau', 'Spätburgunder'],
  characteristics: [
    {
      title: 'Geographische Lage',
      description:
        '100 km lang auf beiden Seiten des Rheins, von der Nahe bis Koblenz und von Kaub bis zum Siebengebirge',
    },
    {
      title: 'Klima',
      description:
        'Viele Sonnentage und windgeschützte Lagen; der Rhein wirkt als Wärmespeicher',
    },
    {
      title: 'Böden',
      description:
        'Schiefer- und Grauwackenverwitterungsböden, vereinzelte Lössinseln; im Norden Böden vulkanischen Ursprungs',
    },
    {
      title: 'Rebfläche 2022',
      description: '468 ha, 2 Bereiche, 10 Großlagen und 111 Einzellagen',
    },
    {
      title: 'Rebsorten 2019 [weiß 84.8%, rot 15.2%]',
      description:
        'Riesling (65%), Müller-Thurgau (4.9%) und Spätburgunder (10%)',
    },
  ],
  custom: {
    title: 'Mittelrhein - Loreley liebt Riesling',
    title2: 'Riesling und Rheinromantik',
    shortDescription:
      'Das Obere Mittelrheintal mit seinen romantischen Burgen, malerischen Steilhängen und mittelalterlichen Dörfern ist seit 2002 UNESCO Weltkulturerbe.',
    promotionalImageUrl,
    backgroundImageUrl,
    externalLink:
      'https://www.deutscheweine.de/tourismus/in-den-anbaugebieten/mittelrhein/',
  },
};

import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Auf rund 460 Hektar präsentiert sich das kleinste der deutschen Weinanbaugebiete mit viel Riesling und dem frühesten Frühling aller Weinregionen. Wenn es im März oder April mancherorts noch fröstelt, setzt an der Hessischen Bergstraße schon die Mandelblüte ein. Von rosafarbenen Blüten gesäumte Wege finden sich dann überall in dem Weinanbaugebiet, dessen Rebfläche sich auf zwei räumlich getrennte Bereiche verteilt. Der hessische Bereich – die Region Starkenburg – erstreckt sich von Darmstadt über Auerbach und Bensheim bis nach Heppenheim. Der zweite, wesentlich kleinere Bereich rund um das Städtchen Groß-Umstadt trägt den offiziellen Namen „Bereich Umstadt“.`}</p>
    <p>{`Der hessische Teil der Bergstraße ist seit 1971 ein eigenständiges Weinanbaugebiet. In der kleinen Weinregion werden vornehmlich trockene und halbtrockene Weine erzeugt. Die Rebsorte Riesling ist typisch für das Gebiet und wird ergänzt durch Rivaner, Grauburgunder, Silvaner, Kerner und Weißburgunder; eine Rarität ist der Rote Riesling. Stark im Kommen sind auch rote Sorten wie Spätburgunder, Dornfelder und St. Laurent. Der Bergsträßer Weinlagenweg ist ein herrlicher Wanderweg mitten durch das Weinanbaugebiet. Die abwechslungsreiche, hügelige Landschaft bietet großartige Ausblicke zum Odenwald und über die Rheinebene. Aber nicht nur an der herrlichen Natur kann man sich erfreuen. Auch die pittoresken Altstadtviertel der Weinstädtchen laden die Gäste zum Verweilen ein. Alljährlich am 1. Mai rufen die Jungwinzer in die Weinberge zwischen Heppenheim und Zwingenberg zur Bergsträßer Weinlagenwanderung auf. Auch die größeren Weinfeste bieten die Möglichkeit, den außerhalb des Anbaugebiets selten zu findenden Wein der Hessischen Bergstraße näher kennen zu lernen. Wer nicht zur Weinfestzeit anreist, kann die raren Tropfen auf jeden Fall in einer der zahlreichen Bergsträßer Weinstuben genießen.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
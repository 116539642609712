/** @jsx jsx */
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import { RichText, Section } from '..';

export default function TextSection({ title, _key, style, _rawText }) {
  return (
    <Section key={_key} sx={style}>
      <Section.Title>{title}</Section.Title>
      <Section.Body>
        <RichText>{_rawText}</RichText>
      </Section.Body>
    </Section>
  );
}

export const fragment = graphql`
  fragment SanityTextSectionFragment on SanityTextSection {
    title
    _rawText
    style {
      ...SectionStyle
    }
  }
`;

import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Silvaner im Bocksbeutel – wohl kaum ein anderes Weinbaugebiet lässt sich bildlich so festhalten wie Franken. Zwar gedeihen auf den knapp 6.170 Hektar Rebfläche rund um Würzburg viele weiße Rebsorten, das spezielle Flaschendesign des Bocksbeutels ist jedoch ein typisch fränkisches Markenzeichen.`}</p>
    <p>{`Begrenzt durch die Rhön im Norden, den Steigerwald im Osten, das Taubertal im Süden und der Spessart im Westen, rangiert Franken auf den mittleren Rängen der Rebflächengröße in Deutschland. Ganz vorne dabei ist das Anbaugebiet jedoch mit dem An- und Ausbau weißer Rebsorten. Besonders der Silvaner gedeiht optimal auf den Muschelkalk- und Keuperböden und ist ein Aushängeschild fränkischer Weinherstellung. Neben Silvaner und Müller-Thurgau kommt der weißen Rebsorte Bacchus als regionale Spezialität ebenfalls eine große Bedeutung zu.`}</p>
    <p>{`Zentrum des Anbaugebiets ist die Barock- und Festspielstadt Würzburg mit berühmten Weinlagen wie dem Würzburger Stein, dessen Erzeugnisse schon zu Goethes Lieblingsweinen zählten. Die Franken sind stolz auf ihre reichhaltige Geschichte, der man auch in den kleineren Weinstädten und auf dem Land begegnet: Idyllische Fachwerkhäuser,, gepflasterte Gassen und romantische Innenhöfe wollen entdeckt zu werden.`}</p>
    <p>{`Die häufig trockenen und warmen Sommer locken Besucherinnen und Besucher zudem in die Natur. Der Weinlandschaft lässt sich auf dem Main-Radweg folgen, Panorama-Höhenwege bieten eine faszinierende Aussicht auf die Weinberge und die reizvolle Landschaft der Volkacher Mainschleife lässt sich wunderbar vom Floß aus erleben.`}</p>
    <p>{`Darüber hinaus versteht man es in Franken, Wein, Kulinarik und Kultur einfallsreich zu kombinieren. Das vielfältige Angebot für Genießerinnen und Genießer bietet unvergessliche Weinerlebnisse und eröffnet neue Horizonte.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
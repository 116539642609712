import useBaseEventData from '@bottlebooks/gatsby-theme-event/src/components/SiteSearch/useEventData';
export * from '@bottlebooks/gatsby-theme-event/src/components/SiteSearch/useEventData';

import useWines from '../../../../components/useWines';
import useExhibitors from '../../../../components/useExhibitors';
import usePackageItems from '../../../../components/usePackageItems';
export default function useEventData() {
  const eventData = useBaseEventData();
  const exhibitors = useExhibitors();
  // const products = useWines();
  const products = usePackageItems();
  return { ...eventData, products, exhibitors };
}

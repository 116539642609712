import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Von Burgunder bis Riesling – als drittgrößtes deutsches Weinbaugebiet hat Baden auf seinen rund 15.800 Hektar Rebfläche so einigen Facettenreichtum zu bieten. Eine ganz bestimmte Eigenschaft hat Baden jedoch allen anderen Weinregionen voraus: Die Sonne zeigt sich hier am häufigsten!`}</p>
    <p>{`Von der Tauber im Norden bis zum Bodensee im Süden – Baden erstreckt sich über eine Länge von 400 Kilometern. Entsprechend vielfältig präsentieren sich die Weine entlang der Badischen Weinstraße. Alle Trauben sind hier war „von der Sonne verwöhnt“, doch die neun Bereiche, in die das Anbaugebiet aufgeteilt ist, präsentieren sich landschaftlich und klimatisch durchaus unterschiedlich.`}</p>
    <p>{`Grundsätzlich ist Baden Burgunderland, so heißt es. Die ganze Rebsortenfamilie ist vertreten – kräftiger Grauburgunder ebenso wie eleganter Weißburgunder oder filigraner Auxerrois. Auch der Spätburgunder erfreut sich in all seinen Ausbaustilen und Geschmacksrichtungen, zum Beispiel als Rosé oder Weißherbst, großer Beliebtheit.`}</p>
    <p>{`Nicht nur das milde Klima, auch die hübschen Weinorte und die landschaftliche Vielfalt locken viele Reisende nach Baden. Neben Städten wie Heidelberg, Konstanz oder Freiburg zieht es Naturfreunde an die Hänge des Odenwalds und Schwarzwalds sowie an die malerischen Ufer des Bodensees. Abwechslungsreich ist auch das Angebot für den Aktivurlaub, sei es Radeln entlang der Tauber, ein Spaziergang auf einem der zahlreichen Weinlehrpfade oder Wandern auf den Weinwanderwegen. Die Badische Weinstraße bietet auf ihren insgesamt 160 Kilometern eine Vielzahl von spannenden Touren.`}</p>
    <p>{`Über 100 Weinfeste laden darüber hinaus zum Feiern ein und auch die mehr als 50 badischen Winzergenossenschaften öffnen regelmäßig an Tagen des offenen Winzerkellers ihre Türen.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
/** @jsx jsx */
import { Text, Title } from '@bottlebooks/gatsby-design-system';
import { useEffect } from 'react';
import { jsx } from 'theme-ui';
import Section from '../Layout/Section';

export default function TryBooking({
  id = 'tryBooking',
  title,
  text,
  variant = 'light',
  eid,
  ...rest
}) {
  useEffect(() => {
    loadTryBooking();
  });
  return (
    <Section id={id} variant={variant} {...rest}>
      <Title>{title}</Title>
      <Text>{text}</Text>
      <div
        className="tryb-widget"
        data-type="expressEmbed"
        data-eid={eid}
      ></div>
    </Section>
  );
}

const loadTryBooking = (callback) => {
  const existingScript = document.getElementById('tryBooking-script');
  // We need to force the widget to reload. Otherwise, it only renders the first time the user loads the page.
  // This widget doesn't seem to have any public functions to reload.
  if (existingScript)
    existingScript?.parentElement?.removeChild(existingScript);
  // This property also needs to be reset in order to ensure the widget reloads
  if (window.trybWidgetsInitialized) window.trybWidgetsInitialized = false;

  const script = document.createElement('script');
  script.src = 'https://www.trybooking.com/us/widget.js';
  script.id = 'tryBooking-script';
  document.body.appendChild(script);
  script.onload = () => {
    if (callback) callback();
  };
};

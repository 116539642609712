import React from 'react';
import promotionalImageUrl from '../../assets/regions/customImages/DWI20_Reg_1700x1200_Rheingau.jpeg';
import backgroundImageUrl from '../../assets/regions/customImages/Rheingau2-scaled.jpeg';
import mapImage from '../../assets/regions/maps/Anbaugebiet-Rheingau-800x498.jpeg';
import mainImage from '../../assets/regions/mainImages/Rheingau1-scaled.jpeg';
import FormattedText from '../../cms-design-system/Elements/MDXFormattedText';
import Description from './rheingau.mdx';

export default {
  // Bottlebooks code
  id: 'de_rheingau',
  // Nice-to-read slug
  slug: 'rheingau',
  countryCode: 'de',
  name: 'Rheingau',
  country: 'Germany',
  mainImageUrl: mainImage,
  description: <FormattedText FormattedText={Description} />,
  mapImageUrl: mapImage,
  association: {
    name: 'Rheingauer Weinwerbung GmbH',
    street: 'Haus der Region, Rheinweg 30',
    postalCode: '65375',
    city: 'Oestrich-Winkel',
    country: 'Germany',
    telephone: '+49 6723 60 272 – 0',
    fax: '+49 67 23 60 272 – 25',
    website: 'https://www.rheingau.com',
    // Simulating what we would need from GraphQL
    websiteText: 'www.rheingau.com',
    email: 'mailto:tourist@rheingau.com',
    emailText: 'tourist@rheingau.com',
  },
  grapeVarieties: ['Riesling', 'Spätburgunder'],
  characteristics: [
    {
      title: 'Geographische Lage',
      description:
        'beginnend am Untermain südlich von Wiesbaden rechtsrheinisch bis Lorchhausen nördlich von Rüdesheim am Rhein',
    },
    {
      title: 'Klima',
      description:
        'Vom Taunus geschützt, milde Winter und warme Sommer, Temperaturmittel 10,6° C',
    },
    {
      title: 'Böden',
      description:
        'schwere tertiäre Mergelböden; Schiefer, Quarzit, Kiesel und Sandstein; Lehm; tiefgründige, meist kalkhaltige Böden aus Sandlöß oder Löß; mittel- und tiefgründige Phyllitschiefer',
    },
    {
      title: 'Rebfläche 2022',
      description: '3200 ha, 1 Bereich, 11 Großlagen, 129 Einzellagen',
    },
    {
      title: 'Rebsorten 2019 [weiß 85.6%, rot 14.4%]',
      description: 'Riesling (77.7%), Spätburgunder (12.2%)',
    },
  ],
  custom: {
    title: 'Rheingau - Wein und Genuss am Fluss',
    title2: 'Königlicher Riesling',
    shortDescription:
      'Die malerischen Steillagen im Rheingau sind überwiegend mit Riesling bepflanzt.',
    promotionalImageUrl,
    backgroundImageUrl,
    externalLink:
      'https://www.deutscheweine.de/tourismus/in-den-anbaugebieten/mittelrhein/',
  },
};

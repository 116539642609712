/**  @jsx jsx */
import {
  Box,
  Container,
  Flex,
  Link,
  Text,
} from '@bottlebooks/gatsby-theme-base';
import BaseLayout from '@bottlebooks/gatsby-theme-event/src/components/Layout/BaseLayout';
import Footer from '@bottlebooks/gatsby-theme-event/src/components/Layout/Footer';
import FooterLogo from '@bottlebooks/gatsby-theme-event/src/components/Layout/FooterLogo';
import Header from '@bottlebooks/gatsby-theme-event/src/components/Layout/Header';
import MainMenu, {
  useMenuQuery,
} from '@bottlebooks/gatsby-theme-event/src/components/Layout/MainMenu';
import useEventTheme from '@bottlebooks/gatsby-theme-event/src/components/Layout/useEventTheme';
import { graphql, useStaticQuery } from 'gatsby';
import { jsx, ThemeProvider } from 'theme-ui';
// import { useScroll } from './useScroll';

export default function Layout({ children, locale, ...rest }) {
  const landingPage = useLandingPage({ locale });
  const theme = useEventTheme();

  // Scrolling is currently disabled because it causes significant
  // peformance degredation on Safari. It should only be reactivated
  // once this issue has been resolved.
  // const { showHeader, isScrolledDown } = useScroll();
  const isScrolledDown = false;
  const showHeader = true;
  return (
    <ThemeProvider theme={theme}>
      <BaseLayout {...rest}>
        <Header
          homePath={landingPage}
          isScrolledDown={isScrolledDown}
          showHeader={showHeader}
          locale={locale}
          sx={{ width: '100%' }}
        >
          <MainMenu
            locale={locale}
            isScrolledDown={isScrolledDown}
            landingPage={landingPage}
          />
        </Header>
        {/* <ErrorMessage/> */}
        <Box as="main">{children}</Box>

        <Footer sx={{ marginTop: 'auto' }}>
          <FooterLogo to={landingPage} />
        </Footer>
      </BaseLayout>
    </ThemeProvider>
  );
}

function ErrorMessage() {
  return (
    <Flex
      sx={{
        backgroundColor: 'brand.lightRed',
      }}
    >
      <Container
        fluid
        sx={{
          maxWidth: 'container.fluid',
        }}
      >
        <Flex
          sx={{
            backgroundColor: 'brand.lightRed',
            paddingX: 2,
            paddingY: 1,
          }}
        >
          <Text sx={{ color: 'brand.warningRed' }}>
            Die Zahlung ist aktuell dank eines technischen Fehlers leider nicht
            möglich - bitte senden Sie mir gerne eine persönliche E-Mail, ich
            werde Sie informieren, sobald der Zahlungsprozess wieder
            funktioniert (
            <Link
              href="mailto:melanie@wein.plus?subject=Bitte%20informieren%2C%20wenn%20Zahlung%20wieder%20m%C3%B6glich%20ist&body=Hallo%2C%0D%0A%0D%0Abitte%20teilen%20Sie%20mir%20mit%2C%20wann%20es%20m%C3%B6glich%20ist%2C%20auf%20der%20Website%20zu%20bezahlen.%0D%0A%0D%0AMit%20freundlichen%20Gr%C3%BC%C3%9Fen%2C"
              target="_blank"
            >
              melanie@wein.plus
            </Link>
            )
          </Text>
        </Flex>
      </Container>
    </Flex>
  );
}

function useLandingPage({ locale }) {
  const { event } = useStaticQuery(query);
  const menuItems = useMenuQuery({ locale });
  if (event.landingPage === 'default') return '/';
  const item = menuItems.find((item) => item.type === event.landingPage);
  if (!item) return '/';
  return item.url;
}

const query = graphql`
  query weinplus_LandingPageQuery {
    event {
      locale
      landingPage
    }
  }
`;

/** @jsx jsx */
import { Box } from '@bottlebooks/gatsby-theme-base';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import ProductCharacteristicsFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/ProductCharacteristicsFacet';
import BrandCityFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Brand/BrandCityFacet';
import BrandCountryFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Brand/BrandCountryFacet';
import BrandDistributionInCountriesFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Brand/BrandDistributionInCountriesFacet';
import BrandDistributionInMarketFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Brand/BrandDistributionInMarketFacet';
import BrandDistributionSoughtCountriesFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Brand/BrandDistributionSoughtCountriesFacet';
import {
  characteristicsToggled,
  cityToggled,
  countryToggled,
  distributionInCountriesToggled,
  distributionInMarketToggled,
  distributionSoughtCountriesToggled,
  eventToggled,
  highlightToggled,
  hospitalityOfferingsToggled,
  lifestyleOfferingsToggled,
  // offeringToggled,
  openingHoursToggled,
  outdoorExperienceOfferingsToggled,
  regionToggled,
  wineExperienceOfferingsToggled,
} from '@bottlebooks/gatsby-theme-event/src/components/Filters/Brand/brandFilterReducer';
import BrandHighlightsFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Brand/BrandHighlightsFacet';
import BrandHospitalityOfferingsFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Brand/BrandHospitalityOfferingsFacet';
import BrandLifestyleOfferingsFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Brand/BrandLifestyleOfferingsFacet';
// import BrandOfferingFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Brand/BrandOfferingFacet';
import BrandOpeningHoursFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Brand/BrandOpeningHoursFacet';
import BrandOutdoorExperienceOfferingsFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Brand/BrandOutdoorExperienceOfferingsFacet';
import BrandRegionFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Brand/BrandRegionFacet';
import BrandWineExperienceOfferingsFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Brand/BrandWineExperienceOfferingsFacet';
import EventsFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Brand/EventsFacet';

export default function BrandFilters({
  facets,
  dispatch,
  state,
  totalCount,
  count,
  children,
  ...rest
}) {
  // console.log(facets);  // Uncomment to debug the facets.
  return (
    <Box {...rest}>
      {children}
      <EventsFacet
        allValues={facets.event}
        selectedValues={state.events}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) => dispatch(eventToggled(value), isActive)}
        multiSelect
      />
      <BrandRegionFacet
        multiSelect
        allValues={facets.region}
        selectedValues={state.regions}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) => dispatch(regionToggled(value), isActive)}
      />
      <BrandHighlightsFacet
        allValues={facets.highlights}
        selectedValues={state.highlights}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) =>
          dispatch(highlightToggled(value), isActive)
        }
        multiSelect
      />
      {/* <BrandOfferingFacet
        allValues={facets.offerings}
        selectedValues={state.offerings}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) =>
          dispatch(offeringToggled(value), isActive)
        }
      /> */}

      <BrandWineExperienceOfferingsFacet
        allValues={facets.wineExperienceOfferings}
        selectedValues={state.wineExperienceOfferings}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) =>
          dispatch(wineExperienceOfferingsToggled(value), isActive)
        }
      />

      <BrandHospitalityOfferingsFacet
        allValues={facets.hospitalityOfferings}
        selectedValues={state.hospitalityOfferings}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) =>
          dispatch(hospitalityOfferingsToggled(value), isActive)
        }
      />
      <BrandLifestyleOfferingsFacet
        allValues={facets.lifestyleOfferings}
        selectedValues={state.lifestyleOfferings}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) =>
          dispatch(lifestyleOfferingsToggled(value), isActive)
        }
      />
      <BrandOutdoorExperienceOfferingsFacet
        allValues={facets.outdoorExperienceOfferings}
        selectedValues={state.outdoorExperienceOfferings}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) =>
          dispatch(outdoorExperienceOfferingsToggled(value), isActive)
        }
      />

      <BrandCountryFacet
        allValues={facets.country}
        selectedValues={state.countries}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) =>
          dispatch(countryToggled(value), isActive)
        }
      />
      <ProductCharacteristicsFacet
        allValues={facets.characteristics}
        selectedValues={state.characteristics}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) =>
          dispatch(characteristicsToggled(value), isActive)
        }
      />
      <BrandCityFacet
        allValues={facets.city}
        selectedValues={state.cities}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) => dispatch(cityToggled(value), isActive)}
      />
      <BrandOpeningHoursFacet
        allValues={facets.openingHours}
        selectedValues={state.openingHours}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) =>
          dispatch(openingHoursToggled(value), isActive)
        }
      />

      <BrandDistributionInMarketFacet
        allValues={facets.distributionInMarket}
        selectedValues={state.distributionInMarket}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) =>
          dispatch(distributionInMarketToggled(value), isActive)
        }
      />

      <BrandDistributionInCountriesFacet
        allValues={facets.distributionInCountries}
        selectedValues={state.distributionInCountries}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) =>
          dispatch(distributionInCountriesToggled(value), isActive)
        }
      />

      <BrandDistributionSoughtCountriesFacet
        allValues={facets.distributionSoughtCountries}
        selectedValues={state.distributionSoughtCountries}
        totalCount={totalCount}
        count={count}
        onToggle={(value, isActive) =>
          dispatch(distributionSoughtCountriesToggled(value), isActive)
        }
      />
    </Box>
  );
}

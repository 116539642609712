import React from 'react';
import promotionalImageUrl from '../../assets/regions/customImages/DWI20_Reg_1700x1200_Wuerttemberg-.jpeg';
import backgroundImageUrl from '../../assets/regions/customImages/Wuerttemberg2-scaled.jpeg';
import mapImage from '../../assets/regions/maps/Anbaugebiet-Wuerttemberg-800x1503.jpeg';
import mainImage from '../../assets/regions/mainImages/Wuerttemberg1-scaled.jpeg';
import FormattedText from '../../cms-design-system/Elements/MDXFormattedText';
import Description from './wuerttemberg.mdx';

export default {
  // Bottlebooks code
  id: 'de_wurttemberg',
  // Nice-to-read slug
  slug: 'wuerttemberg',
  countryCode: 'de',
  name: 'Württemberg',
  country: 'Germany',
  mainImageUrl: mainImage,
  description: <FormattedText FormattedText={Description} />,
  mapImageUrl: mapImage,
  association: {
    name: 'Weinbauverband Württemberg e. V.',
    street: 'Hirschbergstraße 2',
    postalCode: '74189',
    city: 'Weinsberg',
    country: 'Germany',
    telephone: '+49 7134 80 91',
    fax: '+49 7134 89 17',
    website: 'https://www.wvwue.de/',
    // Simulating what we would need from GraphQL
    websiteText: 'www.wvwue.de/',
    email: 'mailto:info@wvwue.de/',
    emailText: 'info@wvwue.de/',
  },
  grapeVarieties: [
    'Trollinger',
    'Riesling',
    'Lemberger',
    'Schwarzriesling',
    'Spätburgunder',
    'Müller-Thurgau',
    'Kerner',
  ],
  characteristics: [
    {
      title: 'Geographische Lage',
      description:
        'Zwischen Reutlingen und Bad Mergentheim; Zentren in Stuttgart und Heilbronn.',
    },
    {
      title: 'Klima',
      description:
        'Milde Jahrestemperaturen, durch Schwarzwald und Schwäbische Alb geschützte Tallage des Neckars',
    },
    {
      title: 'Böden',
      description:
        'Verschiedene Keuperformationen; am mittleren Neckarraum Muschelkalkinseln',
    },
    {
      title: 'Rebfläche 2022',
      description: '11.360 ha, 6 Bereiche, 17 Großlagen, 210 Einzellagen',
    },
    {
      title: 'Rebsorten 2019 [weiß 32,3% · rot 67,7%]',
      description:
        'Trollinger (18,3%), Riesling (18,7%), Lemberger (15,6%), Schwarzriesling (11,7%), Spätburgunder (11,6%), Müller-Thurgau und Kerner',
    },
  ],
  custom: {
    title: 'Württemberg - Schiller meets Trollinger & Lemberger',
    title2: 'Das Rotwein-Ländle im Süden',
    shortDescription:
      'Die wunderschönen terrassierten Steillagen in Württemberg waren wahrscheinlich schon eine Inspirationsquelle für Philosophen und Dichterfürsten. Und sie sind auch heute noch ein Weinreise in die Rotweinregion wert.',
    promotionalImageUrl,
    backgroundImageUrl,
    externalLink:
      'https://www.deutscheweine.de/tourismus/in-den-anbaugebieten/mittelrhein/',
  },
};

import React from 'react';
import promotionalImageUrl from '../../assets/regions/customImages/DWI20_Reg_1700x1200_Ahr.jpg';
import backgroundImageUrl from '../../assets/regions/customImages/Ahr1-scaled.jpeg';
import mapImage from '../../assets/regions/maps/Anbaugebiet-Ahr-400x320.jpeg';
import mainImage from '../../assets/regions/mainImages/Ahr_Weinregionen__13_-scaled.jpg';
import FormattedText from '../../cms-design-system/Elements/MDXFormattedText';
import Description from './ahr.mdx';

export default {
  // Bottlebooks code
  id: 'de_ahr',
  // Nice-to-read slug
  slug: 'ahr',
  countryCode: 'de',
  name: 'Ahr',
  country: 'Germany',
  mainImageUrl: mainImage,
  description: <FormattedText FormattedText={Description} />,
  mapImageUrl: mapImage,
  // Other attributes
  // Teaser?
  association: {
    name: 'Ahrwein e.V.',
    street: 'Oberstraße 8',
    postalCode: '53474',
    city: 'Bad Neuahr-Ahrweiler',
    country: 'Germany',
    telephone: '+49 2641  9171-0',
    fax: '+49 2641  9171-51',
    website: 'https://www.ahrwein.de',
    // Simulating what we would need from GraphQL
    websiteText: 'www.ahrwein.de',
    email: 'mailto:info@ahrwein.de',
    emailText: 'info@ahrwein.de',
  },
  grapeVarieties: [
    'Dornfelder',
    'Riesling',
    'Portugieser',
    'Müller-Thurgau',
    'Frühburgunder',
    'Spätburgunder',
  ],
  characteristics: [
    {
      title: 'Geographische Lage',
      description: 'Im Nordwesten vom Ahrgebirge begrenzt, im Schutz der Eifel',
    },
    {
      title: 'Klima',
      description:
        'Mild und günstig (Kölner Bucht), teilweise treibhausartig in Steillagen',
    },
    {
      title: 'Böden',
      description:
        'tiefgründig und lössreich im unteren Ahrtal, steinhaltig, teilweise Schiefer und vulkanisches Gestein im mittleren Ahrtal',
    },
    {
      title: 'Rebfläche 2022',
      description: '562 ha, 1 Bereich, 1 Großlage, 40 Einzellagen',
    },
    {
      title: 'Rebsorten 2019 [weiß 17.8%, rot 82.2%]',
      description:
        'Spätburgunder (65,3%), Portugieser, Dornfelder, Riesling (8,2%), Müller-Thurgau; besondere Spezialität: Frühburgunder (6,1%)',
    },
  ],
  custom: {
    title: 'Ahr - Das Tal der roten Traube',
    title2: 'Reich der Rotweine im Norden',
    shortDescription:
      'Klein aber oho! Mit rund 560 Hektar Rebfläche eines der kleinsten Weinanbaugebiete und Paradies für Rotweinliebhaberinnen und -liebhaber.',
    promotionalImageUrl,
    backgroundImageUrl,
    externalLink:
      'https://www.deutscheweine.de/tourismus/in-den-anbaugebieten/ahr/',
  },
};

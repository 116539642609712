import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Auf einer Fläche von etwas über 4.200 Hektar bietet die Nahe eine immense Diversität an Bodentypen. Beste Voraussetzungen für den Anbau unterschiedlichster Rebsorten und vielfältiger Weinstilistiken.`}</p>
    <p>{`Das Weinbaugebiet Nahe erstreckt sich entlang des namensgebenden Flusses am Fuß des Hunsrücks von Martinstein bis Bingen. Eine bewegte Erdgeschichte hat der Nahe-Region eine große Bodenvielfalt beschert. Das ermöglicht den Anbau einer großen Vielzahl an Rebsorten und den Ausbau diverser Weinstile.`}</p>
    <p>{`Vor kalten Winden durch den hohen Hunsrück geschützt, schaffen milde Temperaturen und viel Sonnenschein ein hervorragendes Klima für den Weinbau in dem regenarmen und sonnigen Tal. Hier wachsen zu einem Großteil weiße Rebsorten wie Riesling, Müller-Thurgau, Silvaner, Grau- und Weißburgunder sowie Kerner und Scheurebe. Doch auch rote Sorten wie Spätburgunder, Portugieser und Dornfelder lassen sich in dieser facettenreichen Region anbauen.`}</p>
    <p>{`Auch landschaftlich wird es an der Nahe nicht langweilig: Ob sanfte grüne Hügel, romantische Flusstäler oder dramatische Felsformationen – die Nahe glänzt hier mit Abwechslung. Einen guten Einblick in das Anbaugebiet gewinnen Touristinnen und Touristen auf der idyllischen Naheweinstraße oder dem Naheradweg. Der traumhaft schöne 100 km lange “Weinwanderweg Rhein-Nahe” im Naturpark Soonwald ist ein weiteres Highlight für Aktive. Doch auch für Entspannung ist gesorgt: Bad Kreuznach, Bad Münster am Stein und Bad Sobernheim sind beliebte Wellness-Orte der Entspannung. Die Gäste genießen nicht nur das solehaltige Wasser aus den Heilquellen, sondern lassen es sich auch bei Sauna, Lehmbad, Felkekur oder Vinotherapie wohl ergehen.`}</p>
    <p>{`Den Nahewein – beziehungsweise einen der vielen Naheweine – entdeckt kann man dann am besten in geselliger Runde auf einem der zahlreichen Weinfeste an der Naheweinstraße, die überwiegend im Spätsommer stattfinden.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import ahr from './ahr';
import baden from './baden';
import franken from './franken';
import hessischeBergstrasse from './hessischeBergstrasse';
import mittelrhein from './mittelrhein';
import mosel from './mosel';
import nahe from './nahe';
import pfalz from './pfalz';
import rheingau from './rheingau';
import rheinhessen from './rheinhessen';
import saaleUnstrut from './saaleUnstrut';
import sachsen from './sachsen';
import wuerttemberg from './wuerttemberg';

export default function regions() {
  return [
    ahr,
    baden,
    franken,
    hessischeBergstrasse,
    mittelrhein,
    mosel,
    nahe,
    pfalz,
    rheingau,
    rheinhessen,
    saaleUnstrut,
    sachsen,
    wuerttemberg,
  ];
}

export function getRegion({ regionId }) {
  return regions().find((region) => regionId === region.id);
}

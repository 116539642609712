/** @jsx jsx */
import { jsx } from 'theme-ui';
import DefaultFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/DefaultFacet/DefaultFacet';
import { Plural } from '@lingui/macro';

export default function ProductRegionFacet({
  allValues,
  selectedValues,
  onToggle,
  totalCount,
  count,
  ...rest
}) {
  return (
    <DefaultFacet
      pageSize={999}
      multiSelect
      title={
        <Plural value={allValues?.length} one="# region" other="# regions" />
      }
      allValues={allValues}
      selectedValues={selectedValues}
      onToggle={onToggle}
      totalCount={totalCount}
      count={count}
      {...rest}
    />
  );
}

/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout/Layout';
import { jsx } from 'theme-ui';
import { Section } from '../cms-design-system';
import {
  Button,
  P,
  Container,
  Text,
  Title,
} from '@bottlebooks/gatsby-theme-base';

export default function DankePage() {
  return (
    <Layout page={{ title: 'Vielen Dank!' }}>
      <Section>
        <Title sx={{ marginY: 3 }}>Vielen Dank!</Title>
        <P>
          Wir haben Ihre Nachricht erhalten und melden uns in Kürze bei Ihnen!
        </P>
        <Button variant="primary" to="/">
          Zur Startseite
        </Button>
      </Section>
    </Layout>
  );
}

/** @jsx jsx */
import { View } from '@bottlebooks/gatsby-design-system';
import { jsx } from 'theme-ui';
import cssOverlay from '@bottlebooks/gatsby-theme-event/src/helpers/cssOverlay';

export default function SliderImage({ image, children, parallax = false }) {
  return (
    <View
      sx={{
        backgroundColor: 'nuanced',
        position: 'relative',
        backgroundImage: `url(${image})`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        ...(parallax && { backgroundAttachment: 'fixed' }),
        // '::after':
        ...cssOverlay(),
      }}
    >
      {children}
    </View>
  );
}

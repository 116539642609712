import createArrayFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/createArrayFacet';

const offeringsFacet = createArrayFacet({
  stateKey: 'highlights',
  itemKey: 'offeringTypes',
  sortBy: 'value',
  sortDirection: 'asc',
});

const highlightsFacet = createArrayFacet({
  stateKey: 'highlights',
  itemKey: 'highlights.values',
  sortBy: 'value',
  sortDirection: 'asc',
});

export default function brandHighlights(config, state) {
  const offerings = offeringsFacet(config, state);
  const highlights = highlightsFacet(config, state);
  // If one of the child facets is disabled, return the other one.
  if (!offerings) return highlights;
  if (!highlights) return offerings;

  // Create a combined facet.
  return {
    ...offerings,

    reducer: (facetValues, brand) =>
      offerings.reducer(highlights.reducer(facetValues, brand), brand),

    matches: (state) => (item) => {
      return offerings.matches(state)(item) || highlights.matches(state)(item);
    },
  };
}

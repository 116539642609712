import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Nördlich und südlich von Dresden, zwischen Meißen und Pirna, erstreckt sich das sächsische Weinanbaugebiet entlang der Elbe. In den idyllisch gelegenen, mit Bruchsteinmauern terrassierten Steillagen in Flussnähe, gedeihen die Reben besonders gut. Das östlichste Weinanbaugebiet Deutschlands ist gleichzeitig auch eines der kleinsten. Doch ganz nach dem Motto “klein, aber fein” werden dafür in Sachsen auf rund 510 Hektar Spitzenqualitäten – insbesondere bei den Weißweinen – gekeltert.`}</p>
    <p>{`Dass hier im Nordosten so hervorragende Qualitäten gekeltert werden können, ist zudem Ausdruck eines förderlichen Klimas: Der stete Wechsel von Tageswärme und kühlen Nachttemperaturen bekommt den Trauben sehr gut und sorgt für reiche Aroma- und Bukettstoffe bei Müller-Thurgau, Riesling, Weißburgunder und Co.`}</p>
    <p>{`Neben den Klassikern der Region finden sich in Sachsen auch Rebraritäten wie Elbling, Gutedel oder Goldriesling – eine Sorte, die nur in Sachsen angebaut wird. Ungeachtet der kleinen Anbaufläche ist die Vielfalt der Weine in Sachsen beachtlich.`}</p>
    <p>{`Trotz allem bleibt Wein aus Sachsen eine Rarität. Man findet ihn vor allem im Anbaugebiet selbst – in gemütlichen Weinstuben und edlen Restaurants, in stilvollen Vinotheken, auf herbstlichen Weinfesten und natürlich bei den Winzern. Diese sind an der Sächsischen Weinstraße zu Hause. Die 55 Kilometer lange Route von Pirna über Dresden, Radebeul und Meißen bis in die idyllischen Elbweindörfer um Diesbar-Seußlitz verbindet kulturhistorische Sehenswürdigkeiten und die reizvolle, vom Weinbau geprägte Landschaft.`}</p>
    <p>{`Auch der 90 Kilometer lange Sächsische Weinwanderweg verbindet die Perlen der Gegend, führt zu den schönsten Weinbergen, zu Aussichtspunkten und Weinkellern. Einen Besuch lohnt auch die Porzellanstadt Meißen, die als Wiege des Sächsischen Weinbaus gilt, der hier eine über 800-jährige Tradition hat. Noch heute wird in Meißen das größte Weinfest der Region gefeiert.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import React from 'react';
import promotionalImageUrl from '../../assets/regions/customImages/DWI20_Reg_1700x1200_Rheinhessen.jpeg';
import backgroundImageUrl from '../../assets/regions/customImages/Rheinhessen2-scaled.jpeg';
import mapImage from '../../assets/regions/maps/Anbaugebiet-Rheinhessen-800x785.jpeg';
import mainImage from '../../assets/regions/mainImages/Rheinhessen1-scaled.jpeg';
import FormattedText from '../../cms-design-system/Elements/MDXFormattedText';
import Description from './rheinhessen.mdx';

export default {
  // Bottlebooks code
  id: 'de_rheinhessen',
  // Nice-to-read slug
  slug: 'rheinhessen',
  countryCode: 'de',
  name: 'Rheinhessen',
  country: 'Germany',
  mainImageUrl: mainImage,
  description: <FormattedText FormattedText={Description} />,
  mapImageUrl: mapImage,
  association: {
    name: 'Rheinhessenwein e.V.',
    street: 'Otto-Lilienthal-Straße 4',
    postalCode: '55232',
    city: 'Alzey',
    country: 'Germany',
    telephone: '+49 6731 95 10 740',
    fax: '+49 6731 95 10 74 99',
    website: 'https://www.rheinhessenwein.de',
    // Simulating what we would need from GraphQL
    websiteText: 'www.rheinhessenwein.de',
    email: 'mailto:info@rheinhessenwein.de',
    emailText: 'info@rheinhessenwein.de',
  },
  grapeVarieties: [
    'Riesling',
    'Müller-Thurgau',
    'Dornfelder',
    'Silvaner',
    'Grauburgunder',
    'Spätburgunder',
    'Weißburgunder',
    'Portugieser',
    'Kerner',
  ],
  characteristics: [
    {
      title: 'Geographische Lage',
      description: 'Im Rheinbogen zwischen Bingen, Mainz, Worms und Alzey',
    },
    {
      title: 'Klima',
      description:
        'Milde Durchschnitts-Temperaturen; im Schutz von Donnersberg, Taunus und Odenwald',
    },
    {
      title: 'Böden',
      description:
        'Löss, Sand, Mergel, Kalkstein, Ton, Rotliegend, Braunerde, Quarzit und Porphyr',
    },
    {
      title: 'Rebfläche 2022',
      description: '26.860 ha, 3 Bereiche, 23 Großlagen, 414 Einzellagen',
    },
    {
      title: 'Rebsorten 2019 [weiß 84.8%, rot 15.2%]',
      description:
        'Riesling (18.1%), Müller-Thurgau (15.2%), Dornfelder (12.5%), Silvaner (7.8%), Grauburgunder (7.3%), Spätburgunder (5.5%), Weißburgunder, Portugieser und Kerner',
    },
  ],
  custom: {
    title: 'Rheinhessen - Weinerlebnis auf Schritt und Tritt',
    title2: 'Großes Weinland voller Entdeckungen',
    shortDescription:
      'Das Land der tausend Hügel: Als größte deutsche Weinregion hat Rheinhessen einiges an Weinbergen zu bieten. Angebaut werden hier vorwiegend weiße Rebsorten.',
    promotionalImageUrl,
    backgroundImageUrl,
    externalLink:
      'https://www.deutscheweine.de/tourismus/in-den-anbaugebieten/rheinhessen/',
  },
};

/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout/Layout';
import { graphql, useStaticQuery } from 'gatsby';
import { jsx } from 'theme-ui';
import * as cms from '../../cms';
import regions from '../../cms/regions';
import * as designSystem from '../../cms-design-system';

export default function CaliforniaLandingPage() {
  const {
    event: { eventId },
  } = useStaticQuery(graphql`
    {
      event {
        eventId
      }
    }
  `);
  // Regions is passed in here because this data is not specific to the regions page.
  // Regions would be added to the regions page in the CMS
  const data = cms[`event_${eventId}`].regions({ regions: regions() });
  return (
    <Layout page={{ title: 'Regionen' }}>
      {data.sections.map((section, index) => {
        const Component = designSystem[section.component];
        return <Component key={index} {...section.data} sx={section.style} />;
      })}
    </Layout>
  );
}

/** @jsx jsx */
import {
  Box,
  Container,
  Flex,
  SemiTitle,
  Title,
  View,
} from '@bottlebooks/gatsby-design-system';
import { Button } from '@bottlebooks/gatsby-theme-base';
import { jsx } from 'theme-ui';
import SliderImage from './SliderImage';
import { graphql } from 'gatsby';

export default function Slider({
  id = 'slider',
  parallax = false,
  imageUrl,
  title,
  subtitle,
  date,
  buttonText,
  height = [287, 315, 350, 400],
  targetSectionId,
  ...rest
}) {
  return (
    <View id={id} sx={{ position: 'relative', height }} {...rest}>
      <SliderImage image={imageUrl} parallax={parallax}>
        {title && (
          <Container sx={{ display: 'block', height: '100%' }}>
            <Flex
              sx={{
                justifyContent: 'space-around',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Flex direction="column" align="flex-start">
                <Title
                  variant="largest"
                  sx={{
                    color: 'white',
                    fontWeight: 400,
                    lineHeight: 1.4,
                    padding: 2,
                    backgroundColor: 'rgb(0,0,0,.45)',
                  }}
                >
                  {title}
                </Title>

                <SemiTitle
                  sx={{
                    color: 'white',
                    // textTransform: 'uppercase',
                    fontWeight: 500,
                    fontSize: [24, 28, 36],
                    lineHeight: 1,
                    padding: 2,
                    backgroundColor: 'rgb(0,0,0,.45)',
                  }}
                >
                  {subtitle}
                </SemiTitle>
                {/* <Button
                variant="primary"
                onClick={() => {
                  const element = document.getElementById(targetSectionId);
                  element?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                  });
                }}
                sx={{
                  fontSize: 17,
                  fontWeight: 500,
                  borderRadius: 100,
                  paddingY: 2,
                  paddingX: 3,
                  backgroundColor: 'rgb(0, 59, 92)',
                }}
              >
                {buttonText}
              </Button> */}
              </Flex>
            </Flex>
          </Container>
        )}
      </SliderImage>
    </View>
  );
}

export const fragment = graphql`
  fragment SanitySliderSectionFragment on SanitySliderSection {
    title
    subtitle
    imageUrl {
      asset {
        url
      }
    }
  }
`;

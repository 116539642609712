/** @jsx jsx */
import { Text, Title } from '@bottlebooks/gatsby-design-system';
import { useEffect } from 'react';
import { jsx } from 'theme-ui';
import Section from '../Layout/Section';

export default function EventBrite({
  id = 'eventBrite',
  title,
  text,
  variant = 'light',
  eventId,
  ...rest
}) {
  useEffect(() => {
    loadEventBrite(() => {
      loadEvent({ eventId });
    });
  });
  return (
    <Section id={id} variant={variant} {...rest}>
      <Title>{title}</Title>
      <Text sx={{ marginBottom: 3 }}>{text}</Text>
      <div id="eventbrite-widget-container"></div>
    </Section>
  );
}

const loadEventBrite = (cb) => {
  const existingScript = document.getElementById('eb_widgets');
  if (existingScript) return cb();
  const script = document.createElement('script');
  script.src = 'https://www.eventbrite.co.uk/static/widgets/eb_widgets.js';
  script.id = 'eb_widgets';
  document.body.appendChild(script);
  script.onload = () => {
    cb();
  };
};

function loadEvent({ eventId, onOrderComplete = () => {} }) {
  window.EBWidgets.createWidget({
    // Required
    widgetType: 'checkout',
    eventId,
    iframeContainerId: 'eventbrite-widget-container',

    // Optional
    iframeContainerHeight: 425, // Widget height in pixels. Defaults to a minimum of 425px if not provided
    onOrderComplete, // Method called when an order has successfully completed
  });
}

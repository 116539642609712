import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Flächenmäßig liegt der Rheingau mit rund 3.200 Hektar eher im unteren Mittelfeld. In Sachen Riesling hat das Anbaugebiet jedoch die Nase ganz weit vorn: Nahezu 80% der Rebfläche ist mit der Weißweinsorte bestockt.`}</p>
    <p>{`Der Rheingau erstreckt sich westlich von Frankfurt entlang des Rheins, der hier einer Laune der Natur folgt: Der sonst in Richtung Norden fließende Rhein biegt bei Wiesbaden fast im rechten Winkel nach Westen ab, um schon 30 Kilometer später bei Rüdesheim am Rhein wieder in Richtung Norden zu fließen.`}</p>
    <p>{`Genau in diesem so genannten Rheinknie, auf dem schmalen Streifen rechts des Flusses, zwischen Wiesbaden und Lorch am Rhein, befinden sich die trockenen, steinigen Hänge des Rheingaus, in denen der Riesling besonders gut wächst. Geschützt vom Taunus übersteht er auch kalte Wintertage und nutzt die lange Reifeperiode zur Ausbildung feiner Fruchtsäuren und Aromen – die Rheingauer Riesling Spätlesen gehören zu den Vorzeigeweinen der Region.`}</p>
    <p>{`Eingebettet in das idyllische Flusspanorama und die Rieslinghänge, machen viele alte Klöster und Schlösser den Rheingau zu einer touristisch sehr beliebten Reiseregion. Auf 120 Kilometern führt die Rheingauer Riesling Route durch malerische Weinorte wie Eltville oder Oestrich-Winkel. Wer die markanten Aussichtspunkte des Rheingaus besucht, wie das Niederwalddenkmal, Schloss Johannisberg, die Hallgartener Zange über Oestrich-Winkel oder die Bubenhäuser Höhe, sieht vor sich die historischen Zentren des Weinbaus liegen.`}</p>
    <p>{`Unterwegs laden zahlreiche Rheingauer Weingüter, Gutsschänken und Straußwirtschaften zur Einkehr ein. Viele Gastronomen bieten das ganze Jahr über weinkulinarische Erlebnisse auf hohem Niveau, die sich auf den diversen Gourmet- und Weinfestivals, die über das Jahr stattfinden, erkunden lassen.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import {
  Box,
  Flex,
  Grid,
  Link,
  SemiTitle,
  Text,
} from '@bottlebooks/gatsby-design-system';
import React from 'react';
import { Button } from '@bottlebooks/gatsby-theme-base';
import bannerImage from '../../assets/dwiLandingHeader.jpg';
import step1Image from '../../assets/steps/VWT20_Funktion01_Paket_800x800-200x200.png';
import step2Image from '../../assets/steps/VWT20_Funktion02_Temp_800x800-200x200.png';
import step3Image from '../../assets/steps/VWT20_Funktion03_Glas_800x800-200x200.png';
import step4Image from '../../assets/steps/VWT20_Funktion04_Web_800x800-200x200.png';
import step5Image from '../../assets/steps/VWT20_Funktion05_Einschenken_800x800-200x200.png';
import discountImage from '../../assets/WeinTour_10_Prozent-100x100.png';
import { MDXFormattedText, Section } from '../../cms-design-system';
import Section1 from './mdxContent/section1.mdx';
import Moderators from './mdxContent/moderators.mdx';
import Section10pcRabatt from './mdxContent/section2/10pcRabatt.mdx';
import SectionMeetTheWinzer from './mdxContent/section2/meetTheWinzer.mdx';
import Step1 from './mdxContent/section3/step1.mdx';
import Step2 from './mdxContent/section3/step2.mdx';
import Step3 from './mdxContent/section3/step3.mdx';
import Step4 from './mdxContent/section3/step4.mdx';
import Step5 from './mdxContent/section3/step5.mdx';
import videoImage from '../../assets/videoImage.png';
import ahrNeedsHelp from '../../assets/regions/customImages/Ahr_needs_help.png';
import speakersImgUrl from '../../assets/speakers.png';
import AhrNeedsHelp from './mdxContent/ahrNeedsHelp.mdx';

export default {
  sections: [
    {
      component: 'Slider',
      data: {
        id: 'banner',
        imageUrl: bannerImage,
        title: 'Willkommen in den besten Lagen',
        subtitle: 'Virtuelle WeinTour 2022',
      },
    },
    {
      component: 'Section',
      data: {
        children: (
          <Section.Wrapper>
            <Section.Body sx={{ paddingY: [2, null, 5] }}>
              {/* Can this be replaced with a component that automatically calculates the # of columns? */}
              <Grid gap={4} columns={[1, 1, 2, 2]}>
                <Box>
                  <Section.Title>
                    Die Virtuelle WeinTour: Das einzigartige Wein- und
                    Tourismusevent geht in die vierte Runde
                  </Section.Title>
                  <SemiTitle variant="large" sx={{ fontWeight: 500 }}>
                    11. bis 20. März 2022
                  </SemiTitle>
                </Box>
                <Text>
                  <MDXFormattedText FormattedText={Section1} />
                </Text>
              </Grid>
            </Section.Body>
          </Section.Wrapper>
        ),
      },
    },
    {
      component: 'Section',
      data: {
        variant: 'dark',
        children: (
          <Section.Wrapper>
            <Section.Title sx={{ marginBottom: 3 }}>
              Ihre Moderatoren
            </Section.Title>
            <Section.Body>
              <Grid gap={4} columns={[1, 1, 2, 2]}>
                <Box>
                  <MDXFormattedText FormattedText={Moderators} />
                  <Button to={'/packages'} variant="primary">
                    Paket mit Ihren Lieblingsmoderator*innen finden
                  </Button>
                </Box>
                <img src={speakersImgUrl} width="100%" />
              </Grid>
            </Section.Body>
          </Section.Wrapper>
        ),
      },
    },
    {
      component: 'Section',
      data: {
        children: (
          <Section.Wrapper>
            <Section.Title sx={{ marginBottom: 4 }}>
              So funktioniert's
            </Section.Title>
            <Section.Body>
              {/* Can this be replaced with a component that automatically calculates the # of columns? */}
              <Grid gap={4} columns={[1, 1, 5, 5]}>
                <Flex direction="column" align="center">
                  <img
                    src={step1Image}
                    width="150"
                    height="150"
                    style={{ marginBottom: '16px' }}
                  />
                  <MDXFormattedText FormattedText={Step1} />
                </Flex>
                <Flex direction="column" align="center">
                  <img
                    src={step2Image}
                    width="150"
                    height="150"
                    style={{ marginBottom: '16px' }}
                  />
                  <MDXFormattedText FormattedText={Step2} />
                </Flex>
                <Flex direction="column" align="center">
                  <img
                    src={step3Image}
                    width="150"
                    height="150"
                    style={{ marginBottom: '16px' }}
                  />
                  <MDXFormattedText FormattedText={Step3} />
                </Flex>
                <Flex direction="column" align="center">
                  <img
                    src={step4Image}
                    width="150"
                    height="150"
                    style={{ marginBottom: '16px' }}
                  />
                  <MDXFormattedText FormattedText={Step4} />
                </Flex>
                <Flex direction="column" align="center">
                  <img
                    src={step5Image}
                    width="150"
                    height="150"
                    style={{ marginBottom: '16px' }}
                  />
                  <MDXFormattedText FormattedText={Step5} />
                </Flex>
              </Grid>
              <Flex
                sx={{
                  paddingTop: 5,
                  paddingBottom: 2,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button to={'/packages'} variant="primary">
                  Ihr Lieblingspaket finden
                </Button>
              </Flex>
              {/* <Flex
                sx={{
                  marginTop: 4,
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: 1,
                  borderColor: 'primary',
                  padding: 3,
                }}
              >
                <img src={step2Image} width="100" height="100" />
                <MDXFormattedText FormattedText={Step2} />
              </Flex> */}
            </Section.Body>
          </Section.Wrapper>
        ),
      },
    },
    {
      component: 'Section',
      data: {
        variant: 'dark',
        children: (
          <Section.Wrapper>
            <Section.Title>Wieder mit dabei</Section.Title>
            <Section.Body>
              {/* Can this be replaced with a component that automatically calculates the # of columns? */}
              <Grid gap={4} columns={[1, 1, 2, 2]}>
                <MDXFormattedText FormattedText={SectionMeetTheWinzer} />
                <Box sx={{ position: 'relative' }}>
                  <img
                    src={discountImage}
                    style={{ float: 'right', right: 0, top: 0, width: 72 }}
                  />
                  <MDXFormattedText FormattedText={Section10pcRabatt} />
                </Box>
              </Grid>
            </Section.Body>
          </Section.Wrapper>
        ),
      },
    },
    {
      component: 'Section',
      data: {
        children: (
          <Section.Wrapper>
            <Section.Title sx={{ marginBottom: 3 }}>
              Ahr – A wineregion needs Help for Rebuilding
            </Section.Title>
            <Section.Body>
              <Grid gap={4} columns={[1, 1, 2, 2]}>
                <Box>
                  <MDXFormattedText FormattedText={AhrNeedsHelp} />
                </Box>
                <img src={ahrNeedsHelp} width="100%" />
              </Grid>
            </Section.Body>
          </Section.Wrapper>
        ),
      },
    },
    {
      component: 'Section',
      data: {
        variant: 'dark',
        children: (
          <Section.Wrapper>
            <Section.Title sx={{ marginBottom: 3 }}>
              Aufzeichnungen der Online-Verkostungen
            </Section.Title>
            <Section.Body>
              <Grid gap={4} columns={[1, 1, 2, 2]}>
                <Link to={'/videos'}>
                  <img src={videoImage} width="100%" />
                </Link>
                <Text>
                  Nach den Live-Verkostungen können Sie{' '}
                  <Link to={'/videos'}>hier</Link> die aufgezeichneten Webinare
                  ansehen.
                </Text>
              </Grid>
            </Section.Body>
          </Section.Wrapper>
        ),
      },
    },
    {
      component: 'Section',
      data: {
        children: (
          <Section.Wrapper>
            <Section.Title sx={{ marginBottom: 3 }}>
              Aussteller, Tipps und touristische Highlights in den Regionen
            </Section.Title>
            <Section.Body sx={{ height: 600, position: 'relative' }}>
              <Text>
                Entdecken Sie weitere spannende Möglichkeiten, unsere
                Weinregionen zu erkunden und zu genießen!
              </Text>
              <Link to={'/map'}>
                <Text sx={{ marginBottom: 3 }}>
                  Zeige Kartendarstellung auf dem gesamten Bildschirm
                </Text>
              </Link>
              <iframe
                style={{
                  width: '100%',
                  height: '500px',
                  marginTop: '24px',
                  border: 0,
                }}
                src="https://www.deepmoire-maps.com/VirtuelleWeinTour_v24/"
                width="400"
              />
            </Section.Body>
          </Section.Wrapper>
        ),
      },
    },
  ],
};

/** @jsx jsx */
import { gql } from '@apollo/client';
import { Box } from '@bottlebooks/gatsby-design-system';
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout/Layout';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import {
  ContactSection,
  Faqs,
  IframeSection,
  ReferencedSection,
  SimpleGridSection,
  Slider,
  TextSection,
} from '.';

export default function SanityPage({ title, content, slug }) {
  return (
    <Layout page={{ title }}>
      {content.map((data) => {
        const contentItem = afterQuery(data);
        const { style } = contentItem;
        switch (contentItem.__typename) {
          case 'SanityFaqSection': {
            return (
              <Faqs
                key={contentItem._key}
                questions={contentItem.questions}
                title={contentItem.title}
                content={content}
                sx={style}
              />
            );
          }

          case 'SanitySliderSection': {
            return (
              <Slider
                key={contentItem._key}
                imageUrl={contentItem.imageUrl.asset.url}
                title={contentItem.title}
                subtitle={contentItem.subtitle}
                sx={style}
              />
            );
          }

          case 'SanityIframeSection': {
            return <IframeSection key={contentItem._key} {...contentItem} />;
          }

          case 'SanityTextSection': {
            return <TextSection key={contentItem._key} {...contentItem} />;
          }

          case 'SanityContactFormSection': {
            return <ContactSection key={contentItem._key} {...contentItem} />;
          }

          case 'SanityReferencedSection': {
            return (
              <ReferencedSection key={contentItem._key} {...contentItem} />
            );
          }

          case 'SanitySimpleGridSection': {
            return (
              <SimpleGridSection key={contentItem._key} {...contentItem} />
            );
          }

          default: {
            return <Box>Component not supported</Box>;
          }
        }
      })}
    </Layout>
  );
}

function afterQuery(contentItem) {
  const { style = {} } = contentItem;
  const { paddingTop = 4, paddingBottom = 4 } = style;
  return {
    ...contentItem,
    style: {
      ...style,
      // We're going to slightly reduce padding on smartphones automatically
      paddingTop: [paddingTop - 1, null, paddingTop],
      paddingBottom: [paddingBottom - 1, null, paddingBottom],
    },
  };
}

export const fragment = graphql`
  fragment SanityPage on SanityPage {
    slug {
      current
    }
    title
    content {
      __typename

      ... on SanityTextSection {
        _key
        ...SanityTextSectionFragment
      }
      ... on SanityIframeSection {
        _key
        ...SanityIframeSectionFragment
      }
      ... on SanitySliderSection {
        _key
        ...SanitySliderSectionFragment
      }
      ... on SanityFaqSection {
        _key
        ...SanityFaqSectionFragment
      }
      ... on SanityContactFormSection {
        _key
        ...SanityContactSectionFragment
      }
      ... on SanityReferencedSection {
        _key
        ...SanityReferencedSectionFragment
      }
      ... on SanitySimpleGridSection {
        _key
        ...SanitySimpleGridSectionFragment
      }
    }
  }

  # Common elements
  fragment CallToAction on SanityCallToActionElement {
    label
    url
    variant
  }

  fragment SectionStyle on SanitySectionStyle {
    variant
    paddingTop
    paddingBottom
  }
`;

/**
 * This is for preview.
 *
 * Was not successful getting ${Component.fragment} pattern to work. Runtime complains of null.
 *
 */
SanityPage.fragment = gql`
  fragment SanityPage on Page {
    id: _id
    slug {
      current
    }
    title
    content {
      __typename

      ... on TextSection {
        _key
        title
        _rawText: textRaw
        style {
          ...SectionStyle
        }
      }
      ... on IframeSection {
        _key
        title
        _rawText: textRaw
        url
        iframeHeight
      }
      ... on SliderSection {
        _key
        title
        subtitle
        imageUrl {
          asset {
            url
          }
        }
      }
      ... on FaqSection {
        _key
        title
        questions {
          question
          answer: answerRaw
        }
        style {
          ...SectionStyle
        }
      }
      ... on ContactFormSection {
        _key
        title
        subtitle
        subject
        to
        _rawText: textRaw
      }
      ... on ReferencedSection {
        _key
        sectionId
      }
      ... on SimpleGridSection {
        _key
        title
        callToAction {
          ...CallToAction
        }
        blocks {
          ... on IconBlock {
            _key
            icon {
              ...ImageWithPreview
            }
            label
            _rawText: textRaw
            callToAction {
              ...CallToAction
            }
          }
          ... on TextBlock {
            _key
            _rawText: textRaw
            callToAction {
              ...CallToAction
            }
          }
          ... on TitleBlock {
            _key
            title
            subtitle
          }
          ... on ImageBlock {
            _key
            image {
              ...ImageWithPreview
            }
          }
        }
        style {
          ...SectionStyle
        }
      }
    }
  }
  fragment CallToAction on CallToActionElement {
    label
    url
    variant
  }

  fragment SectionStyle on SectionStyle {
    variant
    paddingTop
    paddingBottom
  }

  fragment ImageWithPreview on Image {
    asset {
      _id
      url
    }
  }
`;

import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Mit seinen 11.360 Hektar Rebfläche gehört Württemberg zu den großen Weinregionen in Deutschland. Bekannt ist das viertgrößte Weinbaugebiet vor allem für seine Rotweine: Zwei Drittel Prozent der Fläche sind hier mit roten Rebsorten bestockt.`}</p>
    <p>{`Zwischen Reutlingen im Süden und Bad Mergentheim in Norden befinden sich die Weinlagen Württembergs. Viele Weinberge liegen in den Flusstälern entlang des Neckars. Aber auch an den Nebenflüssen von Rems, Enz, Kocher, Jagst und Tauber sowie am Bodensee bauen die “Wengerter” (Weingärtner) ihre Reben an.`}</p>
    <p>{`Milde Jahrestemperaturen, sowie die durch den Schwarzwald und die Schwäbische Alb geschützten Tallagen des Neckars, sind beste Voraussetzungen für den Rotweinanbau. Die bekannteste Rebsorte aus dem Ländle ist nach wie vor der Trollinger – häufig zu einem süffigen Rotwein gekeltert, der als sogenanntes Nationalgetränk der Württemberger gerne und häufig zur Vesper getrunken wird. Zu den gängigsten roten Rebsorten zählen darüber hinaus Schwarzriesling, Lemberger und Spätburgunder. Bei den Weißweinen dominiert der Riesling.`}</p>
    <p>{`Der Weinkonsum ist in Württemberg deutlich höher als anderswo. Der größte Teil der heimischen Produktion wird an Ort und Stelle konsumiert. Die vielen Weinfeste im Jahr oder die unzähligen gemütlichen Besenwirtschaften entlang der Württemberger Weinstraße mit typisch-schwäbischer Küche laden jedoch nicht nur Ortsansässige dazu ein, die Weine der Region zu verkosten. Bei dem ein oder anderen Glas Wein lässt sich dann auch der philosophisch-literarischen Historie Württembergs wunderbar auf den Grund gehen. Der erste Präsident der Bundesrepublik soll, durch ein Glas Lemberger beflügelt, seine Reden geschrieben haben, auch Schiller und Hölderlin fanden wohl Inspiration beim Württemberger Wein.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
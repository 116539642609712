// The variants are actually imported by the theme, but colocating helps finding them more easily.

export default {
  _base: {
    // DIN
    fontFamily: 'heading',
    // But thinner
    fontWeight: '400',
    textTransform: 'uppercase',
    textDecoration: 'underline',
    textUnderlineOffset: '1px',
  },

  large: {
    variant: 'label._base',
    fontSize: 'default',
    lineHeight: 'default',
    letterSpacing: 1,
  },

  menuItem: {
    variant: 'label._base',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 'smaller',
    letterSpacing: 0.5,
    textDecoration: 'none',
  },

  default: {
    variant: 'label._base',
    fontSize: 'smallest',
    lineHeight: 'smallest',
    letterSpacing: 1,
  },

  small: {
    variant: 'label._base',
    fontSize: 10,
    lineHeight: '16px',
    letterSpacing: 0.8,
    textDecoration: 'none',
  },

  radio: {
    variant: 'label._base',
    fontSize: 'smallest',
    lineHeight: 'smallest',
    letterSpacing: 'smallest',
  },
};

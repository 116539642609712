// The variants are actually imported by the theme, but colocating helps finding them more easily.

const textVariants = {
  large: {
    variant: 'text.default',
    fontWeight: 'light',
    fontSize: 'large',
    lineHeight: 'large',
    letterSpacing: 'large',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  semiTitle: {
    variant: 'title._base',
    fontWeight: 'semibold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  default: {
    fontFamily: 'body',
    fontWeight: 'body',
    fontSize: 'body',
    lineHeight: 'body',
    letterSpacing: 'body',
  },

  small: {
    variant: 'text.default',
    fontSize: 'body',
    lineHeight: 'body',
    letterSpacing: 'body',
  },

  smallest: {
    variant: 'text.default',
    fontSize: 'body',
    lineHeight: 'body',
    letterSpacing: 'body',
  },
};

export default textVariants;

/**
 * @typedef {keyof textVariants} TextVariants
 */

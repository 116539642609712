/** @jsx jsx */
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import { RichText, Section } from '..';

export default function IframeSection({ title, style, _rawText, url, height }) {
  return (
    <Section sx={style}>
      <Section.Title>{title}</Section.Title>
      <Section.Body>
        <RichText>{_rawText}</RichText>
        <iframe
          style={{
            width: '100%',
            height: height ? `${height}px` : '500px',
            marginTop: 3,
            border: 0,
          }}
          src={url}
        />
      </Section.Body>
    </Section>
  );
}

export const fragment = graphql`
  fragment SanityIframeSectionFragment on SanityIframeSection {
    title
    _rawText
    url
    iframeHeight
    style {
      ...SectionStyle
    }
  }
`;

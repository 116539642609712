import React from 'react';
import promotionalImageUrl from '../../assets/regions/customImages/DWI20_Reg_1700x1200_Sachsen.jpeg';
import backgroundImageUrl from '../../assets/regions/customImages/Sachsen2-scaled.jpeg';
import mapImage from '../../assets/regions/maps/Anbaugebiet-Sachsen-800x685.jpeg';
import mainImage from '../../assets/regions/mainImages/Sachsen2-scaled.jpeg';
import FormattedText from '../../cms-design-system/Elements/MDXFormattedText';
import Description from './sachsen.mdx';

export default {
  // Bottlebooks code
  id: 'de_sachsen',
  // Nice-to-read slug
  slug: 'sachsen',
  countryCode: 'de',
  name: 'Sachsen',
  country: 'Germany',
  mainImageUrl: mainImage,
  description: <FormattedText FormattedText={Description} />,
  mapImageUrl: mapImage,
  association: {
    name: 'Weinbauverband Sachsen e.V.',
    street: 'Dresdner Straße 7',
    postalCode: '01662',
    city: 'Meißen',
    country: 'Germany',
    telephone: '+49 3521 76 35 30',
    fax: '+49 6771-599-607',
    website: 'https://www.weinbauverband-sachsen.de',
    // Simulating what we would need from GraphQL
    websiteText: 'www.weinbauverband-sachsen.de',
    email: 'mailto:info@weinbauverband-sachsen.de',
    emailText: 'info@weinbauverband-sachsen.de',
  },
  grapeVarieties: [
    'Müller-Thurgau',
    'Riesling',
    'Weißburgunder',
    'Grauburgunder',
    'Spätburgunder',
  ],
  characteristics: [
    {
      title: 'Geographische Lage',
      description:
        'Elbtal und Nebentäler zwischen Pirna und Diesbar-Seußlitz über 55 km, daneben Bereiche Elstertal (Sachsen-Anhalt) und Schlieben (Brandenburg).',
    },
    {
      title: 'Klima',
      description:
        'milde Jahrestemperaturen, gemäßigtes Kontinentalklima, mittlere Niederschläge',
    },
    {
      title: 'Böden',
      description:
        'Vielfältig von Granit- und Granitporphyrverwitterungen bis zu Lehm, Löss und Sandstein',
    },
    {
      title: 'Rebfläche 2022',
      description: '510 ha, 2 Bereiche, 4 Großlagen, 17 Einzellagen',
    },
    {
      title: 'Rebsorten 2019 [weiß 81,9%, rot 18,1%]',
      description:
        'Müller-Thurgau (14,2%), Riesling (12,6%), Weißburgunder (12,4%), Grauburgunder (9,5%), Spätburgunder (7,9%)',
    },
  ],
  custom: {
    title: 'Sachsen - Cool Climate Saxony – Winzer, Weine & Visionen',
    title2: 'Spezialitäten und Raritäten im Nordosten',
    shortDescription:
      'Das blaue Band der Elbe zieht sich durch eine idyllische Landschaft, die von Weinbergterrassen und historischen Orten geprägt wird.',
    promotionalImageUrl,
    backgroundImageUrl,
    externalLink:
      'https://www.deutscheweine.de/tourismus/in-den-anbaugebieten/sachsen/',
  },
};

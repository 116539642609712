/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import { Box, Grid, Link, Text } from '@bottlebooks/gatsby-design-system';
import { Button } from '@bottlebooks/gatsby-theme-base';
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout/Layout';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import { Section, Slider } from '../../cms-design-system';
import sliderImage from '../../assets/WeinTour_TV_Web-1-scaled.jpeg';

export default function SpeakersPage({ data }) {
  const { speakers } = afterQuery(data);
  return (
    <Layout>
      <Slider imageUrl={sliderImage} />
      <Section>
        <Section.Body sx={{ paddingTop: 4 }}>
          <Button to={'/links'} variant="primary">
            Zeitplan und alle Verkostungslinks
          </Button>
        </Section.Body>
      </Section>
      <Section>
        <Section.Title sx={{ paddingBottom: 3 }}>Moderation</Section.Title>
        <Section.Body>
          <Grid gap={2} width={170}>
            {speakers.map((speaker) => (
              <Link to={`/speakers/${speaker.slug.current}`}>
                <Box
                  key={speaker.name}
                  sx={{
                    position: 'relative',
                    ':hover > div': {
                      maxHeight: '70%',
                      transition: 'max-height 0.2s ease-in-out',
                    },
                  }}
                >
                  <img
                    width="100%"
                    src={`${speaker.profileImage?.asset?.url}?fit=crop&h=170&w=170&dpr=2`}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      width: '100%',
                      height: '100%',
                      maxHeight: 0,
                      backgroundColor: 'white',
                      overflow: 'hidden',
                      transition: 'max-height 0.2s ease-in-out',
                    }}
                  >
                    <Box sx={{ padding: 3 }}>
                      <Text
                        sx={{
                          lineHeight: 1.1,
                          fontWeight: 600,
                          marginBottom: 2,
                        }}
                      >
                        {speaker.name}
                      </Text>
                      <Button
                        variant="primary"
                        sx={{ paddingX: 2, paddingY: 1 }}
                      >
                        Mehr dazu
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Link>
            ))}
          </Grid>
        </Section.Body>
      </Section>
    </Layout>
  );
}

function afterQuery(data) {
  const speakers = data?.allSanitySpeaker?.nodes;
  return {
    speakers,
  };
}

export const query = graphql`
  query SpeakersPage {
    allSanitySpeaker(sort: { fields: [name], order: [ASC] }) {
      nodes {
        slug {
          current
        }
        name
        profileImage {
          asset {
            url
          }
        }
        description: _rawDescription
        externalUrl
        title
        introduction
      }
    }
  }
`;

import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import possibleTypes from '@bottlebooks/gatsby-theme-event/src/wrapElement/apolloClient/possibleTypes';
import typePolicies from '@bottlebooks/gatsby-theme-event/src/wrapElement/apolloClient/typePolicies';
import fetch from 'isomorphic-fetch';
import sanityPossibleTypes from './sanityPossibleTypes';
import sanityTypePolicies from './sanityTypePolicies';

// We need to support 2 GraphQL endpoints for preview
// The BB endpoint supports preview of data for pages generated with BB content
// The Sanity enpoint supports preview of data for pages created with Sanity
// At the moment, we only support preview for Pages in Sanity
// https://stackoverflow.com/questions/69629051/using-multiple-endpoints-in-apollo-client
const graphqlUri =
  process.env.GATSBY_GRAPHQL_URI || 'https://api.bottlebooks.me/graphql';
console.log(graphqlUri);

const bottlebooksGraphQlLink = new HttpLink({
  uri: graphqlUri,
  credentials: 'include',
  fetch,
});

const sanityGraphqlUri = `https://${process.env.GATSBY_SANITY_PROJECT_ID}.api.sanity.io/v1/graphql/production/default`;

const sanityGraphQlLink = new HttpLink({
  uri: sanityGraphqlUri,
  // Even though the API is public, drafts can only be read by users logged into Sanity.
  credentials: 'include',
  fetch,
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    possibleTypes: { ...possibleTypes, ...sanityPossibleTypes },
    typePolicies: { ...typePolicies, ...sanityTypePolicies },
  }),
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === 'sanity',
    sanityGraphQlLink, //if above
    bottlebooksGraphQlLink
  ),
});

export default client;

/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout/Layout';
import { jsx } from 'theme-ui';
import { getRegion } from '../../cms/regions';
import { RegionPage } from '../../cms-design-system';

// This simulates the routes object in the CMS
// The regions use the Bottlebooks ID, but the slug can be something else.
const slugs = {
  ahr: 'de_ahr',
  baden: 'de_baden',
  franken: 'de_franken',
  'hessische-bergstrasse': 'de_hesse',
  mittelrhein: 'de_mittelrhein',
  mosel: 'de_mosel',
  nahe: 'de_nahe',
  pfalz: 'de_pfalz',
  rheingau: 'de_rheingau',
  rheinhessen: 'de_rheinhessen',
  'saale-unstrut': 'de_saale',
  sachsen: 'de_sachsen',
  wuerttemberg: 'de_wurttemberg',
};

export default function RegionBasePage(props) {
  const regionId = props.params.region;
  const region = getRegion({ regionId: slugs[regionId] });
  return (
    <Layout page={{ title: region?.name }}>
      <RegionPage region={region} />
    </Layout>
  );
}

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
// @ts-check
import { BottleImagePlaceholder, Box } from '@bottlebooks/gatsby-theme-base';
import { graphql } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';
import React from 'react';
import { jsx } from 'theme-ui';

export * from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductImage';

const dimensions = {
  smaller: { width: 40, height: 40 },
  default: { width: [70, 100], height: [105, 150] },
  large: { width: [140, 200], height: [210, 300] },
  xlarge: { height: [420, 600] },
};

const paddings = {
  smaller: {},
  default: { padding: [1, 2] },
  large: { padding: [1, 2] },
};

export default function ProductImage({
  product,
  size = 'default',
  className = undefined,
  ...rest
}) {
  if (!product) return null;
  const { bottleImage } = product;
  const { width, height, ...dimensionsRest } = dimensions[size];
  return (
    <Box
      sx={{
        backgroundColor: 'background',
        position: 'relative',
        ...dimensions[size],
        ...paddings[size],
        maxWidth: '100%',
        boxSizing: 'content-box',
        color: 'lighterText',
      }}
      className={className}
    >
      {bottleImage?.asset ? (
        <Image
          alt=""
          {...bottleImage}
          width={Array.isArray(width) ? width[width.length - 1] : width}
          // height={Array.isArray(height) ? height[height.length - 1] : height} // TODO height doesn't work due to a bug in sanity/image-url upstream library: https://github.com/sanity-io/image-url/issues/32
          config={{ fit: 'fill' }}
          sx={{ maxWidth: width, height, marginX: 'auto', display: 'block' }}
          sizes={`(max-width: 40em) ${
            Array.isArray(width) ? width[0] : width
          }px, ${Array.isArray(width) ? width[width.length - 1] : width}px`}
          {...rest}
        />
      ) : (
        <BottleImagePlaceholder
          sx={{
            width: '100%',
            height: '100%',
            color: colors[product.wineColor],
          }}
          {...rest}
        />
      )}
    </Box>
  );
}

const colors = {
  Weiß: '#f8d080',
  white: '#f8d080',
  Rose: '#f196bb',
  Rosé: '#f196bb',
  rose: '#f196bb',
  Rot: '#a20e09',
  red: '#a20e09',
};

export const fragment = graphql`
  fragment sanity_ProductImage on SanityProduct {
    wineColor
    bottleImage {
      ...ImageWithPreview
    }
  }
`;

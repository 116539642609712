import React from 'react';
import promotionalImageUrl from '../../assets/regions/customImages/DWI20_Reg_1700x1200_Franken.jpeg';
import backgroundImageUrl from '../../assets/regions/customImages/Franken2-scaled.jpeg';
import mapImage from '../../assets/regions/maps/Anbaugebiet-Franken-600x388.jpeg';
import mainImage from '../../assets/regions/mainImages/Franken1-scaled.jpeg';
import FormattedText from '../../cms-design-system/Elements/MDXFormattedText';
import Description from './franken.mdx';

export default {
  // Bottlebooks code
  id: 'de_franken',
  // Nice-to-read slug
  slug: 'franken',
  countryCode: 'de',
  name: 'Franken',
  country: 'Germany',
  mainImageUrl: mainImage,
  description: <FormattedText FormattedText={Description} />,
  mapImageUrl: mapImage,
  association: {
    name: 'Badischer Wein GmbH',
    street: 'Mauerbergstraße 32',
    postalCode: '76534',
    city: 'Baden-Baden',
    country: 'Germany',
    telephone: '+49 761  89 78 47 84',
    fax: '+49 761  89 76 63 96',
    website: 'https://www.badischerwein.de',
    // Simulating what we would need from GraphQL
    websiteText: 'www.badischerwein.de',
    email: 'mailto:info@badischerwein.de',
    emailText: 'info@badischerwein.de',
  },
  grapeVarieties: [
    'Müller-Thurgau',
    'Silvaner',
    'Bacchus',
    'Riesling',
    'Scheurebe',
    'Rieslaner',
    'Spätburgunder',
    'Domina',
    'Portugieser',
    'Regent',
  ],
  characteristics: [
    {
      title: 'Geographische Lage',
      description:
        'Zwischen Aschaffenburg und Schweinfurt an den südwärts gerichteten Talhängen des Mains und seiner Nebenflüsse',
    },
    {
      title: 'Klima',
      description:
        'überwiegend kontinental mit trockenen, warmen Sommern und kalten Wintern',
    },
    {
      title: 'Böden',
      description:
        'Verwitterungsböden des Urgesteins und Buntsandsteins im Mainviereck in Unterfranken und Spessart, Lehm-, Löss- und Muschelkalkböden im Maindreieck bei Wertheim und Miltenberg, Keuperböden im Bereich Steigerwald.',
    },
    {
      title: 'Rebfläche 2022',
      description: '6.170 ha, aufgeteilt in 23 Großlagen und 216 Einzellagen',
    },
    {
      title: 'Rebsorten 2019',
      description:
        'Müller-Thurgau (24.3%), Silvaner (24.8%), Bacchus (12.3%), Riesling, Scheurebe, Spezialitäten wie Rieslaner, Spätburgunder, Domina, Portugieser, Regent',
    },
  ],
  custom: {
    title: 'Franken - Wein.Schöner.Land! Reise zum Frankenwein',
    title2: 'Bocksbeutelland',
    shortDescription:
      'Die mittelalterliche Festung Marienberg in Würzburg – die Barockstadt bildet das Zentrum des Anbaugebiets Franken.',
    promotionalImageUrl,
    backgroundImageUrl,
    externalLink:
      'https://www.deutscheweine.de/tourismus/in-den-anbaugebieten/franken/',
  },
};

/** @jsx jsx */
import { gql } from '@apollo/client';
import { Box } from '@bottlebooks/gatsby-theme-base';
import { graphql } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';
import { jsx } from 'theme-ui';

export * from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandLogo';

const dimensions = {
  smaller: { width: 32, height: 32 },
  small: { width: [42, 84], height: [42, 84] },
  default: { width: [60, 120], height: [60, 120] },
  large: { width: [142, 184], height: [142, 184] },
};
const paddings = {
  smaller: { padding: 1 },
  small: { padding: [1, 2] },
  default: { boxShadow: undefined },
  large: { padding: [1, 2] },
};

export default function BrandLogo({
  brand,
  className = undefined,
  size = 'small',
  onDark = false,
}) {
  const { logo } = brand || {};
  const hasLogo = Boolean(logo?.asset);
  const { width, height } = dimensions[size];
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: 'background',
        borderRadius: 'default',
        position: 'relative',
        ...dimensions[size],
        boxSizing: 'content-box',
        color: 'lighterText',
        boxShadow: onDark ? undefined : `0 0 0 1px ${theme.colors?.border}`,
        ...paddings[size],
      })}
      className={className}
    >
      {hasLogo && (
        <Image
          {...logo}
          width={Array.isArray(width) ? width[width.length - 1] : width}
          // height={Array.isArray(height) ? height[height.length - 1] : height} // TODO height doesn't work due to a bug in sanity/image-url upstream library: https://github.com/sanity-io/image-url/issues/32
          alt={brand.name}
          sizes={`(max-width: 40em) ${
            Array.isArray(width) ? width[0] : width
          }px, ${Array.isArray(width) ? width[width.length - 1] : width}px`}
          sx={{
            objectFit: 'contain',
            width,
            height,
            borderRadius: 'default',
          }}
        />
      )}
    </Box>
  );
}

/** @jsx jsx */
import { gql } from '@apollo/client';
import { Box } from '@bottlebooks/gatsby-theme-base';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import CoverImage from '../CoverImage';

export * from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandBanner';

export default function BrandBanner({ name, mainImage, className }) {
  const image = mainImage;
  return (
    <Box
      sx={{
        minHeight: [200, 250],
        maxHeight: '70vh',
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: 'light',
      }}
      className={className}
    >
      {image && (
        <CoverImage
          {...image}
          hotspot={
            image.hotspot || {
              x: 0.5,
              y: 0.35,
              width: 0.2,
              height: 0.2,
            }
          } // Default to hotspot at the upper 35% of the image.
          loading="eager"
          width={1280}
          height={640}
          sizes="100vw"
          sx={{ position: ['absolute', null, 'static'] }}
          alt={name}
        />
      )}
    </Box>
  );
}

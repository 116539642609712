/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import { SanityPage } from '../cms-design-system';

export default function FAQsPage({ data }) {
  return <SanityPage {...data.sanityPage} />;
}

export const query = graphql`
  query ($id: String) {
    sanityPage(id: { eq: $id }) {
      ...SanityPage
    }
  }
`;
